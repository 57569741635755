import React from "react";
import { useEffect, useState } from "react";
import { showSweetAlert } from "components/Shared/CustomAlert";
import { useDispatch } from "react-redux";
// react-bootstrap components
import { Form, Button, Card, Col, Row, Table } from "react-bootstrap";

const ChangePassword = (props) => {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "500px");
  }, []);

  const [input, setInput] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      //   const validPassword = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})");
      const validPassword = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{6,}$"
      );

      switch (name) {
        case "currentPassword":
          if (!value) {
            stateObj[name] = "Please enter your current password.";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter a new Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else if (!validPassword.test(input.password)) {
            stateObj["password"] =
              "Password must contain at least 6 characters, including UPPER/lowercase and numbers, and at least 1 special character.";
          } else {
            stateObj["password"] = "";
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const savePassword = async () => {
    if (
      input.currentPassword != "" &&
      input.password != "" &&
      input.confirmPassword != ""
    ) {
      if (
        error.currentPassword == "" &&
        error.password == "" &&
        error.confirmPassword == ""
      ) {
        try {
          const url = `account/ChangePassword?userId=${localStorage.getItem(
            "userId"
          )}&userToken=${localStorage.getItem("userToken")}&currentPassword=${
            input.currentPassword
          }&newPassword=${input.password}`;

          const response = await fetch(url, {
            method: "post",
            headers: [
              ["Content-Type", "application/json"],
              ["Content-Type", "text/plain"],
            ],
          });
          if (response.status === 200) {
            showSweetAlert("saveSuccess", `Password`, () => {
              handleSavePasswordSuccess();
            });
            props.setShowChangePassword(false);
          } else {
            const message = await response.json();
            showSweetAlert("warningOkOnly", message);
          }
        } catch (err) {
          showSweetAlert("serverError");
        }
      }
    } else {
      showSweetAlert(
        "warningOkOnly",
        `Please enter your current password and new password to continue.`
      );
    }
  };

  return (
    <>
      <Form.Group>
        <label>Current Password</label>
        <Form.Control
          type="password"
          name="currentPassword"
          placeholder="Enter Current Password"
          value={input.currentPassword}
          onChange={onInputChange}
          onBlur={validateInput}
        ></Form.Control>

        {error.currentPassword && (
          <label style={{ color: "red" }} className="err">
            {error.currentPassword}
          </label>
        )}
      </Form.Group>

      <Form.Group>
        <label htmlFor="exampleInputEmail1">New Password</label>

        <Form.Control
          type="password"
          name="password"
          placeholder="Enter Password"
          value={input.password}
          onChange={onInputChange}
          onBlur={validateInput}
        ></Form.Control>
        {error.password && (
          <label style={{ color: "red" }} className="err">
            {error.password}
          </label>
        )}
      </Form.Group>
      <div className="mb-4">
        <Form.Group>
          <label>Confirm New Password</label>
          <Form.Control
            type="password"
            name="confirmPassword"
            placeholder="Enter Confirm Password"
            value={input.confirmPassword}
            onChange={onInputChange}
            onBlur={validateInput}
          ></Form.Control>
          {error.confirmPassword && (
            <label style={{ color: "red" }} className="err">
              {error.confirmPassword}
            </label>
          )}
        </Form.Group>
      </div>
      <div>
        <Button
          className="btn-fill float-end"
          variant="primary"
          onClick={savePassword}
        >
          Change Password
        </Button>
        <Button
          className="btn-fill float-end"
          variant="primary"
          onClick={() => props.setShowChangePassword(false)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
export default ChangePassword;
