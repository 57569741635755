import React from "react";
import { useMemo } from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// react-bootstrap components

export default function CustomSelect(props) {
  const { id, data, withAll, withBlank } = props;

  const selectOptions = useMemo(() => {
    const options = data.map((e) => {
      return {
        value: e.id,
        label: e.value,
      };
    });

    if (withAll)
      return [
        {
          value: 0,
          label: "ALL",
        },
      ].concat(options);

    if (withBlank)
      return [
        {
          value: 0,
          label: "",
        },
      ].concat(options);

    return options;
  }, [data]);

  const value = useMemo(() => {
    if (selectOptions) {
      const val = selectOptions.filter((e) => e.value === (props.value || id));
      if (val && val.length > 0) {
        return val[0];
      } else {
        return {
          value: null,
          label: "",
        };
      }

      // Just in case
      //if (props.tab === "manage-category" || props.placeholder === "Category Value" || props.placeholder === "Supplier" || props.placeholder === "Size Group" || props.placeholder === "Category Name" || props.placeholder === "Brand" || props.placeholder === "Season" || props.placeholder === "VAT Rate") {
      //    const val = selectOptions.filter((e) => e.value === (props.value || id));
      //    if (val)
      //        return val[0];
      //} else {
      //    const val = selectOptions.filter((e) => e.value === (id || props.value));
      //    if (val)
      //        return val[0];
      //}
    }
    return null;
  }, [selectOptions, id, props.value]);

  return (
    <>
      {props.title && <h5 className="title">{props.title}</h5>}
      <Select
        className="react-select primary custom-select"
        classNamePrefix="react-select"
        name={props.name}
        closeMenuOnSelect={true}
        value={value}
        onChange={(value) => props.handleChange(value)}
        options={selectOptions}
        placeholder={props.placeholder ? props.placeholder + " select" : ""}
        isDisabled={props.isDisabled ? props.isDisabled : false}
      />
    </>
  );
}
