import React, { useEffect, useState } from "react";
import CustomTable from "components/Shared/CustomTable";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
  Form,
  CardHeader,
  Tab,
  Tabs,
} from "react-bootstrap";
// old table
// import OrderLinesTable from "./OrderLinesTable";

import FulfillmentOrderLinesTable from "./FulfillmentOrderLinesTable";
import OrderLinesTable from "./OrderLinesTable";

function OrdersModal(props) {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "1350px");
  }, []);

  var hasPermission = false;
  var permissions = localStorage.getItem("permissions");

  if (permissions.includes("Orders.Edit")) {
    hasPermission = true;
  }

  const orderLines = props.SingleOrder?.orderLines;

  return (
    <>
      {alert}
      <Container fluid className="PurchaseModal">
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Order Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="3">
                  <Form.Group>
                    <label>Order Number</label>
                    <Form.Control
                      placeholder="Order Number"
                      type="text"
                      value={props.SingleOrder.orderNumber}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Location</label>
                    <Form.Control
                      placeholder="Location"
                      type="text"
                      value={props.SingleOrder.locationName}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Total Discount</label>
                    <Form.Control
                      placeholder="Discount"
                      type="text"
                      value={`£${props.SingleOrder?.discount?.toFixed(2)}`}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Total Price</label>
                    <Form.Control
                      placeholder="Price"
                      type="text"
                      value={`£${props.SingleOrder?.totalPrice?.toFixed(2)}`}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Form.Group>
                    <label>fulFillment Status</label>
                    <Form.Control
                      placeholder="Fulfillment Status"
                      type="text"
                      value={props.SingleOrder.fulfillmentStatus}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Quantity</label>
                    <Form.Control
                      placeholder="Financial Status"
                      type="text"
                      value={props.SingleOrder.quantity}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Order Date</label>
                    <Form.Control
                      placeholder="Order Date"
                      type="text"
                      value={new Date(
                        props.SingleOrder.createdAt
                      ).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3"></Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Container>
      <Container>
        <Tabs 
          defaultActiveKey="OrderLines" 
          transition={false} 
          animation={false} 
          id="uncontrolled-tab-example" 
          className="mb-1 ms-1">
          <Tab eventKey="OrderLines" title="Order Lines" >
        <OrderLinesTable
          SingleOrder={props.SingleOrder}
              orderLinesData={orderLines}
              isLodaing={props.isLodaing}
              handleSearchChange={props.handleSearchChange}
              search={props.search}
              handleAddMovementProduct={props.handleAddMovementProduct}
              handleGetItemCard={props.handleGetItemCard}
              locationDropdown={props.locationDropdown}
              handleShowEditOrderLocationModal={
                props.handleShowEditOrderLocationModal
              }
        />
        </Tab>
        {/*<Tab eventKey="FulfillmentOrderLines" title="Fulfillment Order Lines">*/}
        {/*<FulfillmentOrderLinesTable*/}
        {/*  fulfillmentOrdersData={props.fulfillmentOrdersData}*/}
        {/*  setFulfillmentOrdersData={props.setFulfillmentOrdersData}*/}
        {/*  handleChangeFulfillmentOrderLocation={*/}
        {/*    props.handleChangeFulfillmentOrderLocation*/}
        {/*  }*/}
        {/*  data={orderLines}*/}
        {/*  locations={props.locationDropdown}*/}
        {/*  isChangeFulfillmentOrderLocation={*/}
        {/*    props.isChangeFulfillmentOrderLocation*/}
        {/*  }*/}
        {/*  handleCancelChangeFulfillmentOrderLocation={*/}
        {/*    props.handleCancelChangeFulfillmentOrderLocation*/}
        {/*  }*/}
        {/*  orderLinesData={orderLines}*/}
        {/*  checkedLineItems={props.checkedLineItems}*/}
        {/*  handleGetItemCard={props.handleGetItemCard}*/}
        {/*  handleShowEditOrderLocationModal={*/}
        {/*    props.handleShowEditOrderLocationModal*/}
        {/*  }*/}
        {/*  pickedStatusDropdownOptions={props.pickedStatusDropdownOptions}*/}
        {/*  handlePickedStatusChange={props.handlePickedStatusChange}*/}
        {/*  fulfillmentOrderLines={props.fulfillmentOrderLines}*/}
        {/*  handleConfirmChangeFulfillmentOrderLocation={*/}
        {/*    props.handleConfirmChangeFulfillmentOrderLocation*/}
        {/*  }*/}
        {/*/>*/}
        {/*</Tab>*/}
        </Tabs>
      </Container>
    </>
  );
}
export default OrdersModal;
