import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Card, Container } from "react-bootstrap";
import SpecialOrderNotes from "./SpecialOrderNotes";
import { getPermission } from "../../utils/Permissions";
import { SPECIALORDERS_EDIT } from "constants/Permissions";

const UpdateSpecialOrder = (props) => {
    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty("--modal-width", "1300px");
        document.documentElement.style.setProperty("--modal-width-tablet", "50%");
    }, []);

    const returnRequestedBy = (id) => {
        const user = props.users.find((x) => x.id === id);
        if (user) {
            return user.firstName + " " + user.lastName;
        }
        return "";
    };

    const returnStatus = (statusId) => {
        switch (statusId) {
            case 1:
                return "New";
            case 2:
                return "Approved";
            case 3:
                return "Rejected";
            case 4:
                return "POCreated";
            default:
                return "";
        }
    };

    const returnLocation = (locationId) => {
        return props.locations.find((x) => x.id === locationId)?.value;
    };

    return (
        <>
            <Form action="" className="form" method="">
                <Card>
                    <Card.Body>
                        <>
                            <Row>
                                <Col className="pr-1" md="">
                                    <Form.Group>
                                        <label>Product Id</label>
                                        <Form.Control
                                            value={props.data?.productId}
                                            placeholder="Product Id"
                                            name="productId"
                                            type="text"
                                            disabled
                                            onChange={(e) => props.handleSpecialOrderInputChange(e)}
                                        // onBlur={(e) => props.validateInput("name", e.target.value)}
                                        />
                                        {/* {props.error.name && <label style={{ color: "red" }} className='err'>{props.error.name}</label>} */}
                                    </Form.Group>
                                </Col>
                                <Col className="pr-1" md="">
                                    <Form.Group>
                                        <label>Location</label>
                                        <Form.Control
                                            value={returnLocation(props.data?.locationId)}
                                            placeholder="Location"
                                            name="locationId"
                                            type="text"
                                            disabled
                                            onChange={(e) => props.handleSpecialOrderInputChange(e)}
                                        // onBlur={(e) => props.validateInput("name", e.target.value)}
                                        />
                                        {/* {props.error.name && <label style={{ color: "red" }} className='err'>{props.error.name}</label>} */}
                                    </Form.Group>
                                </Col>
                                <Col className="pr-1" md="">
                                    <Form.Group>
                                        <label>Product Name</label>
                                        <Form.Control
                                            value={props.data?.productName}
                                            placeholder="Product name"
                                            name="productName"
                                            type="text"
                                            onChange={(e) => props.handleSpecialOrderInputChange(e)}
                                        // onBlur={(e) => props.validateInput("name", e.target.value)}
                                        />
                                        {/* {props.error.name && <label style={{ color: "red" }} className='err'>{props.error.name}</label>} */}
                                    </Form.Group>
                                </Col>
                                <Col className="pr-1" md="">
                                    <Form.Group>
                                        <label>Product Link</label>
                                        <Form.Control
                                            value={props.data?.productLink}
                                            placeholder="Product link"
                                            name="productLink"
                                            type="text"
                                            onChange={(e) => props.handleSpecialOrderInputChange(e)}
                                        // onBlur={(e) => props.validateInput("name", e.target.value)}
                                        />
                                        {/* {props.error.name && <label style={{ color: "red" }} className='err'>{props.error.name}</label>} */}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {/*<Col className="pr-1" md="">*/}
                                {/*    <Form.Group>*/}
                                {/*        <label>Product Description</label>*/}
                                {/*        <Form.Control*/}
                                {/*            value={props.data?.productDescription}*/}
                                {/*            placeholder="Product Description"*/}
                                {/*            name="productDescription"*/}
                                {/*            type="text"*/}
                                {/*            onChange={(e) => props.handleSpecialOrderInputChange(e)}*/}
                                {/*        // onBlur={(e) => props.validateInput("name", e.target.value)}*/}
                                {/*        />*/}
                                {/*        */}{/* {props.error.name && <label style={{ color: "red" }} className='err'>{props.error.name}</label>} */}
                                {/*    </Form.Group>*/}
                                {/*</Col>*/}

                                <Col className="pr-1" md="3">
                                    <Form.Group>
                                        <label>Purchase Order Id</label>
                                        <Form.Control
                                            value={props.data?.purchaseOrderId}
                                            placeholder="Purchase Order Id"
                                            name="purchaseOrderId"
                                            type="text"
                                            onChange={(e) => props.handleSpecialOrderInputChange(e)}
                                        // onBlur={(e) => props.validateInput("name", e.target.value)}
                                        />
                                        {/* {props.error.name && <label style={{ color: "red" }} className='err'>{props.error.name}</label>} */}
                                    </Form.Group>
                                </Col>
                                <Col className="pr-1" md="3">
                                    <Form.Group>
                                        <label>Requested By</label>
                                        <Form.Control
                                            value={returnRequestedBy(props.data?.requestedById)}
                                            placeholder="Requested By"
                                            name="requestedById"
                                            disabled
                                            type="text"
                                            onChange={(e) => props.handleSpecialOrderInputChange(e)}
                                        // onBlur={(e) => props.validateInput("name", e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className="pr-1" md="3">
                                    <Form.Group>
                                        <label>Status</label>
                                        <Form.Control
                                            value={returnStatus(props.data?.statusId)}
                                            placeholder="Status Id"
                                            name="statusId"
                                            disabled
                                            type="text"
                                            onChange={(e) => props.handleSpecialOrderInputChange(e)}
                                        // onBlur={(e) => props.validateInput("name", e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="pt-4">
                                <SpecialOrderNotes
                                    data={props.specialOrderNotes}
                                    setSpecialOrderNotes={props.setSpecialOrderNotes}
                                    user={props.user}
                                    isEditNote={props.isEditNote}
                                    setIsEditNote={props.setIsEditNote}
                                    selectedNote={props.selectedNote}
                                    setSelectedNote={props.setSelectedNote}
                                    deleteSpecialOrderNote={props.deleteSpecialOrderNote}
                                    addSpecialOrderNote={props.addSpecialOrderNote}
                                    orderId={props.data?.id}
                                    handleAddSpecialOrderNote={props.handleAddSpecialOrderNote}
                                    isAddNote={props.isAddNote}
                                    users={props.users}
                                />
                            </Row>
                        </>
                        <div className="py-3">
                            {props.isAdmin && !props.isAddNote && !props.isEditNote && (
                                <>
                                    <Button
                                        className="btn-fill float-start"
                                        variant="primary"
                                        disabled={
                                            !getPermission(
                                                props.user.permissions,
                                                SPECIALORDERS_EDIT
                                            ) ||
                                            props.data?.statusId === 3 ||
                                            props.data?.statusId === 2
                                        }
                                        onClick={props.handleApproveSpecialOrder}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        className="btn-fill float-start"
                                        variant="primary"
                                        disabled={
                                            !getPermission(
                                                props.user.permissions,
                                                SPECIALORDERS_EDIT
                                            ) ||
                                            props.data?.statusId === 3 ||
                                            props.data?.statusId === 2
                                        }
                                        // onClick={props.handleRejectSpecialOrder}
                                        onClick={props.handleOpenRejectionReasonModal}
                                    >
                                        Reject
                                    </Button>
                                </>
                            )}
                            <Button
                                className="btn-fill float-end"
                                variant="primary"
                                // onClick={props.handleUpdateSpecialOrder}
                                onClick={
                                    !props.isEditNote && !props.isAddNote
                                        ? props.handleUpdateSpecialOrder
                                        : !props.isAddNote
                                            ? props.updateSpecialOrderNote
                                            : props.addSpecialOrderNote
                                }
                            >
                                {!props.isEditNote && !props.isAddNote
                                    ? "Save"
                                    : !props.isAddNote
                                        ? "Update"
                                        : "Add"}
                            </Button>
                            <Button
                                className="btn-fill float-end"
                                variant="info"
                                onClick={props.handleCloseUpdateSpecialOrderModal}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className="clearfix"></div>
                    </Card.Body>
                </Card>
            </Form>
        </>
    );
};

export default UpdateSpecialOrder;
