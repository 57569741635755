import React, { useEffect, useState, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footers/Footer.js";
import { authProvider } from "authProvider.js";
import { AzureAD, AuthenticationState } from "react-aad-msal";

// dinamically create dashboard routes
import routes from "routes.js";

import { getPermission } from "utils/Permissions.js";

import PurchaseOrder from "views/Elysium/PurchaseOrder.js";

function Admin() {
  console.error = (message) => {
    if (message.startsWith("Warning:")) {
      return; // Ignore React warnings
    }
    // Handle other errors normally
    originalConsoleError(message);
  };

  const originalConsoleError = console.error;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isFavourites = useSelector((state) => state.isFavourites);

  const [adAccount, setADAccount] = useState(null);

  const sideRoutes = useMemo(() => {
    let sideRoutes = [...routes];

    if (user?.permissions) {
      sideRoutes = sideRoutes.filter((route) => {
        if (route.permission && !route.path.includes("admin")) {
          return user?.permissions.includes(route.permission);
        }
      });
    }

    return sideRoutes;
  }, [user]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      return <Route path={prop.path} key={key} component={prop.component} />;
    });
  };

  return (
    <>
      {localStorage.getItem("userId") !== null ? (
        <div className="wrapper">
          <Sidebar routes={sideRoutes} />
          <div className="main-panel">
            <AdminNavbar />
            <div className="content">
              <Switch>
                <Route exact path="/" component={PurchaseOrder} />
                {getRoutes(routes)}
              </Switch>
            </div>
            <div
              className="close-layer"
              onClick={() =>
                document.documentElement.classList.toggle("nav-open")
              }
            />
          </div>
        </div>
      ) : (
        <Redirect to="/auth/login" />
      )}
    </>
  );
}

export default Admin;
