import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { showSweetAlert } from "components/Shared/CustomAlert";
import CustomSearchBox from "components/Shared/CustomSearchBox";
import { useDispatch } from "react-redux";
import { formatDate } from "utils/FormatDate.js";

// react-bootstrap components
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
} from "react-bootstrap";

import InfiniteScroll from "react-infinite-scroll-component";
// todo - change to orders perms when exist
import { MOVEMENTS_EDIT } from "constants/Permissions.js";
import { getPermission } from "utils/Permissions.js";

function OrdersTable(props) {
  const SearchStyle = {
    marginTop: "-35px",
    marginLeft: "190px",
  };

  // ### STATE ###
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handlePermission = () => {
    showSweetAlert("unauthorized");
  };
  // ### renderTableData ###
  const renderTableData = () => {
    return props.data.map((log, index) => {
      if (log === null || log === undefined) return;
      const {
        orderLines,
        id,
        orderNumber,
        locationId,
        location,
        customerId,
        customerName,
        subTotal,
        totalPrice,
        totalTax,
        totalDiscount,
        financialStatus,
        fulfillmentStatus,
        refundAmount,
        createdAt,
        shopifyId,
        confirmed,
        quantity,
        orderDate,
      } = log;

      const returnLocation = () => {
        return props.locationDropdown.map((location, index) => {
          if (location.id === log.locationId) {
            return location.value;
          }
        });
      };

      return (
        <tr key={`Orders${index}`}>
          <td onClick={() => props.ordersModal(id)}>
            <a href="#id">{orderNumber}</a>
          </td>
          <td>£{totalPrice.toFixed(2)}</td>
          <td>£{subTotal.toFixed(2)}</td>
          <td>£{totalTax.toFixed(2)}</td>
          <td>{quantity}</td>
          <td>
            {new Date(createdAt).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })}
          </td>
          <td>{fulfillmentStatus}</td>
          <td>{locationId === 0 ? "Web" : returnLocation()}</td>
          {/* <td>{formatDate(dateCreated)}</td> */}
          {/* <td>
            <OverlayTrigger
              href="#pablo"
              onClick={(e) => e.preventDefault()}
              overlay={<Tooltip id="tooltip-255158527">Download Btn 1</Tooltip>}
            >
              <Button
                className="fa-sharp fa-light fa-arrow-down-to-line"
                href="#pablo"
                // onClick={(e) => props.handlePrintBatchLabel(id, batchName)}
                variant="link"
              >
                <i className="fa fa-qrcode"></i>
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              href="#pablo"
              onClick={(e) => e.preventDefault()}
              overlay={<Tooltip id="tooltip-255158527">Download Btn 2</Tooltip>}
            >
              <Button
                href="#"
                // onClick={(e) => props.handleBatchProductLabelPDF(id, batchName)}
                variant="link"
              >
                <i className="fa fa-tag"></i>
              </Button>
            </OverlayTrigger>
          </td> */}
        </tr>
      );
    });
  };
  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <Card.Header></Card.Header>
        <Card.Body className="table-responsive p-0">
          {props.data && props.data.length == 0 && !props.isLoading && (
            <p
              style={{ height: 550 }}
              className="d-flex justify-content-center align-items-center text-center"
            >
              No data found.
            </p>
          )}
          {props.data && props.data.length > 0 && !props.isLoading && (
            <Col md="12">
              <InfiniteScroll
                dataLength={props.data.length}
                next={props.updateData}
                hasMore={true}
                height={650}
                loader={
                  props.data.length >= props.take ? (
                    <h6>Loading more data...</h6>
                  ) : (
                    ""
                  )
                }
              >
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Total Price</th>
                      <th>Sub Total</th>
                      <th>Total Tax</th>
                      <th>Quantity</th>
                      <th>Order Date</th>
                      <th>Fullfillment Status</th>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableData()}</tbody>
                </Table>
              </InfiniteScroll>
            </Col>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default OrdersTable;
