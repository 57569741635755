import React, { useState, useEffect, useMemo } from "react";
import SpecialOrdersTable from "components/SpecialOrders/SpecialOrdersTable";
import RequestSpecialOrder from "components/SpecialOrders/RequestSpecialOrder";
import UpdateSpecialOrder from "components/SpecialOrders/UpdateSpecialOrder";
import RejectionReasonModal from "components/SpecialOrders/RejectionReasonModal";
import CustomModal from "../../components/Shared/CustomModal";
import { useSelector } from "react-redux";
import { showSweetAlert } from "components/Shared/CustomAlert";
import Swal from "sweetalert2";

import { Container } from "react-bootstrap";

const SpecialOrders = () => {
  const user = useSelector((state) => state.user);

  const [isAdmin, setIsAdmin] = useState(false);
  const [specialOrdersData, setSpecialOrdersData] = useState(null);
  const [selectedSpecialOrder, setSelectedSpecialOrder] = useState(null);
  const [productsData, setProductsData] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [specialOrderNotes, setSpecialOrderNotes] = useState([]);
  const [showSpecialOrderNotes, setShowSpecialOrderNotes] = useState(false);
  const [isEditNote, setIsEditNote] = useState(false);
  const [isAddNote, setIsAddNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);
  let cleanSpecialOrder = {
    id: 0,
    locationId: 0,
    productId: 0,
    productName: "",
    productLink: "",
    productDescription: "",
    quantity: 1,
    brandCode: "",
    colour: "",
    size: "",
    requestedById: 0,
    approvedById: 0,
    statusId: 0,
    rejectedReason: "",
    purchaseOrderNumber: "",
  };
  const [newSpecialOrder, setNewSpecialOrder] = useState(cleanSpecialOrder);
  // handle add new modal
  const [showRequestSpecialOrderModal, setShowRequestSpecialOrderModal] =
    useState(false);
  // handle update modal
  const [showUpdateSpecialOrderModal, setShowUpdateSpecialOrderModal] =
    useState(false);

  // special order products table
  const [page, setPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]); // data for infinite scroll

  useEffect(() => {
    fetchAllUsers();
    if (user) {
      setIsAdmin(user.roleName === "Admin");
      fetchAllSpecialOrders(user.roleName === "Admin");
      fetchProducts();
      fetchLocations();
    }
  }, [user]);

  const fetchAllSpecialOrders = async (isAdmin) => {
    try {
      let location = user.locationId;
      if (isAdmin) {
        location = 0;
      }
      let url = `/SpecialOrder/GetAll?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&locationid=${location}`;
      const response = await fetch(url);
      const body = await response.json();
      setSpecialOrdersData(body);
    } catch (err) {}
  };

  //request special order
  const [isLoadingSpecialOrder, setIsLoadingSpecialOrder] = useState(false);
  const requestSpecialOrder = async (isExisting) => {
    if (
      newSpecialOrder.locationId === null ||
      newSpecialOrder.locationId === 0
    ) {
      showSweetAlert("warningOkOnly", "Please select a location.");
      return;
    }

    if (isExisting) {
      if (selectedProduct === null) {
        showSweetAlert("warningOkOnly", "Please select a product.");
        return;
      }
    }

    if (!isExisting) {
      if (
        newSpecialOrder.productDescription === "" ||
        newSpecialOrder.productName === "" ||
        newSpecialOrder.productLink === "" ||
        newSpecialOrder.brandCode === "" ||
        newSpecialOrder.colour === "" ||
        newSpecialOrder.size === "" ||
        newSpecialOrder.locationId === null ||
        newSpecialOrder.locationId === 0
      ) {
        showSweetAlert(
          "warningOkOnly",
          "Please fill in all fields when requesting a new special order."
        );
        return;
      }
    }

    if (newSpecialOrder.quantity === null || newSpecialOrder.quantity <= 0) {
      showSweetAlert("warningOkOnly", "Please enter a valid quantity.");
      return;
    }

    try {
      let url = `/SpecialOrder/Add?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;

      let data;
      if (!isExisting) {
        data = { ...newSpecialOrder, requestedById: user.id };
      } else {
        data = {
          id: 0,
          locationId: newSpecialOrder.locationId,
          // locationName: newSpecialOrder.locationName,
          productId: selectedProduct.id ?? 0,
          productName: selectedProduct.name ?? "",
          productLink: selectedProduct.productLink ?? "",
          productDescription: selectedProduct.description ?? "",
          quantity: newSpecialOrder.quantity ?? 1,
          requestedById: user.id,
          approvedById: 0,
          statusId: 0,
          purchaseOrderNumber: selectedProduct.purchaseOrderNumber ?? "",
          brandCode: selectedProduct.brandCode ?? "",
          colour: selectedProduct.colour ?? "",
          size: selectedProduct.size ?? "",
          rejectedReason: "",
        };
      }

      setIsLoadingSpecialOrder(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const body = await response.json();
      // setSpecialOrdersData(body);

      if (response.status === 200) {
        showSweetAlert("success", "Special Order Requested Successfully");
        handleCloseSpecialOrderModal();
        fetchAllSpecialOrders(isAdmin);
        setIsLoadingSpecialOrder(false);
      } else {
        setIsLoadingSpecialOrder(false);
        showSweetAlert("serverError");
      }

      setNewSpecialOrder(cleanSpecialOrder);
      setSelectedProduct(null);
    } catch (err) {}
  };

  const handleProductSearch = (value) => {
    setSearch(value);
  };

  const pageRef = React.useRef(page);
  const handlePageChange = (newPage) => {
    pageRef.current = newPage;
    setPage(newPage);
  };

  const fetchProducts = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const userId = localStorage.getItem("userId");

      let url = `/Product/GetAllPaged?userId=${userId}&userToken=${userToken}&skip=${skip}&take=${take}&locationId=${0}&brandId=${0}&seasonId=${0}&genderId=${0}&categoryId=${0}&statusId=${0}&includeArchived=${false}&search${search}&orderBy=${"DateCreated"}&descending=${true}`;

      const response = await fetch(url);
      const body = await response.json();
      setProductsData(body.data);

      // setSpecialOrdersData(body);
    } catch (err) {
      console.error(err);
    }
  };

  const updateProductData = async () => {
    try {
      handlePageChange(page + 1);
      let url = `/Product/GetAllPaged?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&skip=${
        pageRef.current * take + skip
      }&take=${take}&locationId=${0}&brandId=${0}&seasonId=${0}&genderId=${0}&categoryId=${0}&statusId=${0}&search=${search}&orderBy=${"DateCreated"}&descending=${true}&includeArchived=${false}`;
      const response = await fetch(url);
      const body = await response.json();
      setTimeout(() => {
        setProductsData(productsData.concat(body.data));
        if (body.data.length == 0) {
          setHasMore(false);
        }
      }, 500);
    } catch (err) {}
  };

  // ### HOOKS ###
  useMemo(async () => {
    handlePageChange(0);
    try {
      setProductsData([]);
      setIsLoading(true);
      let url = `/Product/GetAllPaged?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&skip=${
        page * take + skip
      }&take=${take}&locationId=${0}&brandId=${0}&seasonId=${0}&genderId=${0}&categoryId=${0}&statusId=${0}&search=${search}&orderBy=${"DateCreated"}&descending=${true}&includeArchived=${false}`;
      const response = await fetch(url);
      const body = await response.json();
      setProductsData(body.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }, [search]);

  const handleCloseSpecialOrderModal = () => {
    setShowRequestSpecialOrderModal(false);
    setNewSpecialOrder(cleanSpecialOrder);
    setSelectedProduct(null);
  };
  const handleOpenSpecialOrderModal = () => {
    setShowRequestSpecialOrderModal(true);
  };

  const handleCloseUpdateSpecialOrderModal = () => {
    if (isAddNote) {
      setIsAddNote(false);
      setSelectedNote(null);
      return;
    }
    if (isEditNote) {
      setSelectedNote(null);
      setIsEditNote(false);
      return;
    }

    setNewSpecialOrder(cleanSpecialOrder);
    setShowUpdateSpecialOrderModal(false);
    setSelectedSpecialOrder(null);
    setSelectedNote(null);
    setSpecialOrderNotes([]);
  };
  const handleOpenUpdateSpecialOrderModal = () => {
    setShowUpdateSpecialOrderModal(true);
  };

  const returnSpecialOrderSingle = (id) => {
    setSelectedSpecialOrder(specialOrdersData.find((order) => order.id === id));
    fetchSpecialOrderNotes(id);
  };

  const fetchSpecialOrderNotes = async (id) => {
    try {
      let url = `/SpecialOrderNote/GetAll?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&specialOrderId=${id}`;
      const response = await fetch(url);
      const body = await response.json();
      setSpecialOrderNotes(body);
      // setSpecialOrdersData(body);
    } catch (err) {
      console.error(err);
    }
  };

  const addSpecialOrderNote = async () => {
    try {
      let url = `/SpecialOrderNote/Add?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedNote),
      });
      const body = await response.json();
      showSweetAlert("success", "Special Order Note Added Successfully");
      fetchSpecialOrderNotes(selectedSpecialOrder.id);
      handleCloseUpdateSpecialOrderModal();
      // setSpecialOrdersData(body);
    } catch (err) {
      console.error(err);
    }
  };

  const updateSpecialOrderNote = async () => {
    try {
      let url = `/SpecialOrderNote/Update?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedNote),
      });
      const body = await response.json();
      if (response.status === 200) {
        showSweetAlert("success", "Note Updated Successfully");
        fetchSpecialOrderNotes(selectedSpecialOrder.id);
        handleCloseUpdateSpecialOrderModal();
      } else {
        showSweetAlert("serverError");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const deleteSpecialOrderNote = async (id) => {
    showSweetAlert(
      "warning",
      "Are you sure you want to delete this note?",
      () => {
        confirmDeleteSpecialOrderNote(id);
      }
    );
  };

  const confirmDeleteSpecialOrderNote = async (id) => {
    try {
      let url = `/SpecialOrderNote/Delete?id=${id}&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url, {
        method: "DELETE",
      });
      const body = await response.json();
      showSweetAlert("success", "Special Order Note Deleted Successfully");
      handleCloseSpecialOrderModal();
      fetchSpecialOrderNotes(selectedSpecialOrder.id);
      // setSpecialOrdersData(body);
    } catch (err) {
      console.error(err);
    }
  };

  const handleShowSpecialOrderNotes = (id) => {
    setShowSpecialOrderNotes(!showSpecialOrderNotes);
  };

  const handleUpdateSpecialOrder = async () => {
    try {
      const url = `SpecialOrder/Update?id=${
        selectedSpecialOrder.id
      }&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;

      let locationName;
      if (selectedSpecialOrder.locationId === 0) {
        locationName = "";
      } else {
        locationName =
          locations.find((x) => x.id === selectedSpecialOrder.locationId)
            .value ?? "";
      }

      const data = {
        ...selectedSpecialOrder,
        locationName: locationName,
        purchaseOrderId: parseInt(selectedSpecialOrder.purchaseOrderId),
      };

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const body = await response.json();
      if (body) {
        showSweetAlert("success", "Special Order Updated Successfully");
        fetchAllSpecialOrders(isAdmin);
        handleCloseUpdateSpecialOrderModal();
      } else {
        showSweetAlert("serverError");
      }
    } catch (err) {}
  };

  const handleApproveSpecialOrder = async () => {
    try {
      const url = `SpecialOrder/Approve?id=${
        selectedSpecialOrder.id
      }&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const body = await response.json();
      if (response.status === 200) {
        showSweetAlert("success", "Special Order Approved Successfully");
        handleCloseUpdateSpecialOrderModal();
        fetchAllSpecialOrders(isAdmin);
      } else {
        showSweetAlert("serverError");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRejectSpecialOrder = async () => {
    try {
      const url = `SpecialOrder/Reject?id=${
        selectedSpecialOrder.id
      }&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&rejectedReason=${
        selectedSpecialOrder.rejectionReason
      }`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const body = await response.json();
      if (response.status === 200) {
        showSweetAlert("success", "Special Order Rejected Successfully");
        handleCloseUpdateSpecialOrderModal();
        handleCloseRejectionReasonModal(true);
        fetchAllSpecialOrders(isAdmin);
      } else {
        showSweetAlert("serverError");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSpecialOrderInputChange = (e) => {
    setSelectedSpecialOrder({
      ...selectedSpecialOrder,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddSpecialOrderNote = () => {
    setIsAddNote(true);
  };

  const fetchAllUsers = async () => {
    try {
      let url = `/user/getall?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url);
      const users = await response.json();
      setUsers(users);
    } catch (err) {}
  };

  const fetchLocations = async () => {
    try {
      let url = `/location/getdropdownlist?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url);
      const body = await response.json();
      const data = body?.map((item) => {
        return { id: item.id, value: item.name };
      });

      data.concat({ id: 0, value: "label" });
      setLocations(data);
    } catch (err) {}
  };

  const [showRejectionReasonModal, setShowRejectionReasonModal] =
    useState(false);
  const handleCloseRejectionReasonModal = (isConfirm) => {
    setShowRejectionReasonModal(false);
    if (!isConfirm) {
      setShowUpdateSpecialOrderModal(true);
    }
    setSelectedSpecialOrder({
      ...selectedSpecialOrder,
      rejectionReason: "",
    });
  };
  const handleOpenRejectionReasonModal = () => {
    setShowUpdateSpecialOrderModal(false);
    setShowRejectionReasonModal(true);
  };

  return (
    <Container fluid>
      <Container fluid>
        <CustomModal
          show={showRequestSpecialOrderModal}
          handleClose={handleCloseSpecialOrderModal}
          title={"Request Special Orders"}
        >
          <RequestSpecialOrder
            data={productsData}
            setData={setProductsData}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            handleSearchChange={handleProductSearch}
            search={search}
            handlePageChange={handlePageChange}
            updateData={updateProductData}
            isLoading={isLoading}
            take={take}
            hasMore={hasMore}
            handleCloseSpecialOrderModal={handleCloseSpecialOrderModal}
            requestSpecialOrder={requestSpecialOrder}
            newSpecialOrder={newSpecialOrder}
            setNewSpecialOrder={setNewSpecialOrder}
            locations={locations}
            cleanSpecialOrder={cleanSpecialOrder}
            isLoadingSpecialOrder={isLoadingSpecialOrder}
          />
        </CustomModal>

        <CustomModal
          show={showRejectionReasonModal}
          handleClose={handleCloseRejectionReasonModal}
          title="Reason for Rejection"
        >
          <RejectionReasonModal
            handleRejectSpecialOrder={handleRejectSpecialOrder}
            handleCloseRejectionReasonModal={handleCloseRejectionReasonModal}
            selectedSpecialOrder={selectedSpecialOrder}
            setSelectedSpecialOrder={setSelectedSpecialOrder}
          />
        </CustomModal>

        <CustomModal
          show={showUpdateSpecialOrderModal}
          handleClose={handleCloseUpdateSpecialOrderModal}
          title={"Update Special Orders"}
        >
          <UpdateSpecialOrder
            isAdmin={isAdmin}
            data={selectedSpecialOrder}
            handleUpdateSpecialOrder={handleUpdateSpecialOrder}
            handleApproveSpecialOrder={handleApproveSpecialOrder}
            handleRejectSpecialOrder={handleRejectSpecialOrder}
            handleSpecialOrderInputChange={handleSpecialOrderInputChange}
            handleCloseUpdateSpecialOrderModal={
              handleCloseUpdateSpecialOrderModal
            }
            // showSpecialOrderNotes={showSpecialOrderNotes}
            // handleShowSpecialOrderNotes={handleShowSpecialOrderNotes}
            specialOrderNotes={specialOrderNotes}
            setSpecialOrderNotes={setSpecialOrderNotes}
            user={user}
            isEditNote={isEditNote}
            setIsEditNote={setIsEditNote}
            selectedNote={selectedNote}
            setSelectedNote={setSelectedNote}
            deleteSpecialOrderNote={deleteSpecialOrderNote}
            handleAddSpecialOrderNote={handleAddSpecialOrderNote}
            isAddNote={isAddNote}
            addSpecialOrderNote={addSpecialOrderNote}
            updateSpecialOrderNote={updateSpecialOrderNote}
            users={users}
            locations={locations}
            handleOpenRejectionReasonModal={handleOpenRejectionReasonModal}
          />
        </CustomModal>
        <SpecialOrdersTable
          // locationId={isAdmin ? 0 : user.locationId}
          data={specialOrdersData}
          requestSpecialOrder={requestSpecialOrder}
          // handleOpenModal={}
          handleOpenSpecialOrderModal={handleOpenSpecialOrderModal}
          handleOpenUpdateSpecialOrderModal={handleOpenUpdateSpecialOrderModal}
          returnSpecialOrderSingle={returnSpecialOrderSingle}
          users={users}
          locations={locations}
        />
      </Container>
    </Container>
  );
};

export default SpecialOrders;
