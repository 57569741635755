import React from "react";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
  Form,
} from "react-bootstrap";
import CustomSearchBox from "components/Shared/CustomSearchBox";
import { createNonNullExpression } from "typescript";

function OrderLinesTable(props) {
  const SearchStyle = {
    marginTop: "-35px",
    marginLeft: "245px",
  };
  const btnStyle = {
    marginTop: "-50px",
    marginLeft: "61%",
  };
  const renderTableData = () => {
    return (
      props.orderLinesData &&
      props.orderLinesData.length > 0 &&
      props.orderLinesData.map((log, index) => {
        if (log === null || log === undefined) return;
        const {
          id,
          orderId,
          productId,
          locationId,
          fulfillmentId,
          subTotal,
          totalTax,
          price,
          discount,
          fulfillmentStatus,
          refunded,
          refundAmount,
          refundLocationId,
          shopifyId,
          quantity,
          sku,
        } = log;

        const returnLocation = () => {
          return props.locationDropdown.map((location, index) => {
            if (location.id === log.locationId) {
              return location.value;
            }
          });
        };

        return (
          <tr key={`OrderLines${index}`}>
            <td>
              <a href="#id" onClick={() => props.handleGetItemCard(productId)}>
                {sku}
              </a>
            </td>
            {/* <td onClick={() => props.handleGetItemCard(productId)}>
              <a href="#id">{productId}</a>
            </td> */}
            {/* <td>{productId}</td> */}
            <td>{returnLocation()}</td>
            <td>{fulfillmentStatus}</td>
            <td>{quantity}</td>
            <td>£{price?.toFixed(2)}</td>
            <td>£{subTotal?.toFixed(2)}</td>
            <td>£{totalTax?.toFixed(2)}</td>
            {/* {fulfillmentStatus === "pending" ? ( */}
            {/*<td className="td-actions text-right d-flex justify-content-end align-items-end">*/}
            {/*  <Button*/}
            {/*    // disabled={fulfillmentStatus !== "pending"}*/}
            {/*    className="btn-link btn-xs btn-action"*/}
            {/*    variant="danger"*/}
            {/*    onClick={() => props.handleShowEditOrderLocationModal(log)}*/}
            {/*  >*/}
            {/*    <i*/}
            {/*      className={*/}
            {/*        fulfillmentStatus != "pending" ? "fas fa-edit" : "d-none"*/}
            {/*      }*/}
            {/*    ></i>*/}
            {/*  </Button>*/}
            {/*</td>*/}
            {/* ) : null} */}
          </tr>
        );
      })
    );
  };
  return (
    <Card className="strpied-tabled-with-hover">
      <Card.Header>
        <Card.Title as="h4">Orders</Card.Title>
        {/* <Col md="3" style={SearchStyle}>
          <CustomSearchBox
            placeholder="Search"
            value={props.search}
            handleChange={(e) => props.handleSearchChange(e)}
            handleClear={() => props.handleSearchChange("")}
          />
        </Col> */}
        {/* {props.SingleOrder.typeId === 3 && (
          <OverlayTrigger
            href="#pablo"
            onClick={(e) => e.preventDefault()}
            overlay={
              <Tooltip id="tooltip-255158527">Add Movement Product</Tooltip>
            }
          >
            <Button
              style={SearchStyle}
              className="btn-fill float-end"
              variant="primary"
              onClick={() => props.handleAddMovementProduct()}
            >
              Add
            </Button>
          </OverlayTrigger>
        )} */}
      </Card.Header>
      <Card.Body className="table-responsive p-0">
        {props.isLoading ? (
          <div
            style={{ height: 120 }}
            className="d-flex justify-content-center align-items-center text-center"
          >
            <div>
              <Spinner animation="border" />
              <p>Loading...</p>
            </div>
          </div>
        ) : (
          <>
            {props.orderLinesData && props.orderLinesData.length == 0 && (
              <p
                style={{ height: 120 }}
                className="d-flex justify-content-center align-items-center text-center"
              >
                No data found.
              </p>
            )}
          </>
        )}
        {props.orderLinesData?.length !== 0 && !props.isLoading && (
          <Col md="20">
            <Table className="table-hover table-striped w-full">
              <thead>
                <tr>
                  <th>Sku</th>
                  {/* <th>Sku</th> */}
                  <th>Location</th>
                  <th>Fulfillment Status</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Sub Total</th>
                  <th>Total Tax</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </Table>
          </Col>
        )}
      </Card.Body>
    </Card>
  );
}
export default OrderLinesTable;
