import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showSweetAlert } from "components/Shared/CustomAlert";
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
} from "react-bootstrap";

import { SUPPLIER_EDIT, SUPPLIER_DELETE } from "../../constants/Permissions.js";
import { getPermission } from "../../utils/Permissions.js";

function SpecialOrdersTable(props) {
  const user = useSelector((state) => state.user);

  const handlePermission = () => {
    showSweetAlert("unauthorized");
  };

  const returnStatus = (statusId) => {
    switch (statusId) {
      case 1:
        return "New";
      case 2:
        return "Approved";
      case 3:
        return "Rejected";
      case 4:
        return "POCreated";
      default:
        return "";
    }
  };

  const returnEmployeeDetails = (id) => {
    const user = props.users.find((x) => x.id === id);
    if (user) {
      return user.firstName + " " + user.lastName;
    }
    return "";
  };

  const renderTableData = (permissionEdit, permissionDelete) => {
    return (
      props.data &&
      props.data.map((data, index) => {
        const {
          approvedByID,
          id,
          locationId,
          locationName,
          productDescription,
          productId,
          productLink,
          productName,
          purchaseOrderId,
          quantity,
          requestedById,
          statusId,
        } = data;
        return (
          <tr key={`SpecialOrder${index}${id}`}>
            <td
              onClick={() => {
                props.handleOpenUpdateSpecialOrderModal(id);
                props.returnSpecialOrderSingle(id);
              }}
            >
              <a href="#id">{id}</a>
            </td>
            <td>{productName}</td>
            <td>{productLink}</td>
            <td>
              {props.locations.find((x) => x.id === locationId)?.value ?? ""}
            </td>
            <td>{quantity}</td>
            <td>{returnEmployeeDetails(requestedById)}</td>
            <td>{returnEmployeeDetails(approvedByID)}</td>
            <td>{purchaseOrderId}</td>
            <td>{returnStatus(statusId)}</td>
          </tr>
        );
      })
    );
  };

  return (
    <>
      {alert}
      <Card className="table-with-links elysium-h100">
        <Card.Header>
          <Card.Title
            as="h4"
            className="d-flex justify-content-between align-items-center"
          >
            Special Orders
            <Button
              className="btn-fill"
              variant="primary"
              onClick={() => props.handleOpenSpecialOrderModal()}
            >
              Request Special Order
            </Button>
          </Card.Title>
          <p className="card-category"></p>
        </Card.Header>
        <Card.Body className="table-full-width elysium-h90">
          <Col className="overflow-auto elysium-h100">
            <Table>
              <thead>
                <tr>
                  <th className="text-left">#</th>
                  <th>Product Name</th>
                  <th>Product Link</th>
                  <th>Location</th>
                  <th>Quantity</th>
                  <th>Requested By</th>
                  <th>Approved By</th>
                  <th>Purchase Order Number</th>
                  <th>Status</th>
                  {/* <th className="text-right">Actions</th> */}
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </Table>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
}

export default SpecialOrdersTable;
