import { formLabelClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Card, Container } from "react-bootstrap";

const RejectionReasonModal = (props) => {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "500px");
    document.documentElement.style.setProperty("--modal-width-tablet", "50%");
  }, []);

  return (
    <>
      <Form action="" className="form" method="">
        <Card>
          <Card.Body>
            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Rejection Reason</Form.Label>
                    <Form.Control
                      className="h-75"
                      as="textarea"
                      rows={3}
                      placeholder="Enter reason for rejection"
                      onChange={(e) => {
                        props.setSelectedSpecialOrder({
                          ...props.selectedSpecialOrder,
                          rejectionReason: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col></Col>
                <Col>
                  <Button
                    className="btn-fill float-end"
                    variant="primary"
                    onClick={() => props.handleRejectSpecialOrder()}
                  >
                    Confirm
                  </Button>
                  <Button
                    className="btn-fill float-end"
                    variant="info"
                    onClick={() => props.handleCloseRejectionReasonModal(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default RejectionReasonModal;
