import React from "react";
import { Badge, Form } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CustomSelect from "./CustomSelect";
import { useEffect, useState } from "react";

function CustomAutoComplete(props) {
  return (
    <div>
      <div style={{ marginBottom: "5px" }}>
        <CustomSelect
          placeholder="Tags"
          data={props.items}
          handleChange={(e) => props.handleAddItem(e)}
          isDisabled={props.disabled}
          withAll={props.label === "Add Brands" ? true : false}
        />
      </div>
      <div>
        {props.selectedItems?.map((item) => (
          <Badge
            key={item.id}
            className="custom-badge"
            style={{ marginTop: "10px" }}
            onClick={() =>
              !props.disabled ? props.handleRemoveItem(item) : ""
            }
          >
            {item.value}
            <i className="fas fa-times custom-badge-icon" />
          </Badge>
        ))}
      </div>
    </div>
  );
}

export default CustomAutoComplete;
