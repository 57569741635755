import React, { useEffect, useState } from "react";
import CustomTable from "components/Shared/CustomTable";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
  Form,
  CardHeader,
} from "react-bootstrap";
import CustomSelect from "../../components/Shared/CustomSelect";
// import OrderLinesTable from "./OrderLinesTable";
// todo - import the order lines table
import FulfillmentOrderLinesTable from "./FulfillmentOrderLinesTable";

function EditOrderLocationModal(props) {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "500px");
  }, []);

  var hasPermission = false;
  var permissions = localStorage.getItem("permissions");

  if (permissions.includes("Orders.Edit")) {
    hasPermission = true;
  }

  const orderLines = props.SingleOrder?.orderLines;

  return (
    <>
      {alert}
      <Container fluid className="PurchaseModal">
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Edit Order Fulfillment Location</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group>
                    <label>Location</label>
                    <CustomSelect
                      placeholder="Location"
                      data={props.locationDropdown}
                      handleChange={(e) => {
                        props.setSelectedOrderLine({
                          ...props.selectedOrderLine,
                          locationId: e.value,
                        });
                        // props.setSelectedFromLocation(e.value);
                      }}
                      withAll={false}
                      id={props.selectedOrderLine.locationId}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-fill float-end"
                variant="info"
                onClick={props.handleHideEditOrderLocationModal}
              >
                Cancel
              </Button>
              <Button
                className="btn-fill float-end"
                variant="primary"
                onClick={() => props.handleUpdateOrderLocation()}
              >
                Save
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Container>
    </>
  );
}
export default EditOrderLocationModal;
