import React, { useState, useEffect, useMemo } from "react";
import {
    Card,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux'
import LabelsTable from "../../components/Labels/LabelsTable";
import { showSweetAlert } from 'components/Shared/CustomAlert';

import { LABELS_VIEW } from 'constants/Permissions.js';
import { getPermission } from 'utils/Permissions.js';
function Labels() {
    // ### STATE ###
    const user = useSelector((state) => state.user);
    
    const [labels, setLabels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // ### HANDLERS ###
    //Print labels pdf
    const fetchLabels = async () => {
        try {
            let url = `/label/getall?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const data = await response.json();
            setIsLoading(false);
            setLabels(data);
        }
        catch (e) {
            showSweetAlert('serverError');
        }
    }

    const handlePrintLabel = async () => {
        try {
            const url = `label/print?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);

            if (response.status == 200) {
                const base64String = await response.text();

                try {
                    const decodedData = Buffer.from(base64String, "base64").toString("binary");
                    const dataArray = new Uint8Array(Array.from(decodedData, (char) => char.charCodeAt(0)));
                    const blob = new Blob([dataArray], { type: "application/pdf" });

                    const blobUrl = window.URL.createObjectURL(blob);
                    const iframe = document.createElement("iframe");
                    iframe.style.display = "none";
                    iframe.src = blobUrl;
                    document.body.appendChild(iframe);

                    iframe.onload = function () {
                        try {
                            iframe.contentWindow.print();
                        } catch (printError) {
                            console.error("Error in printing:", printError);
                        }
                    };
                } catch (error) {
                    console.error("Error in processing the PDF:", error);
                }
            } else {
                const body = await response.text();
                showSweetAlert('warningCustomMessage', body);
            }

        }
        catch (e) {
            showSweetAlert('serverError');
        }
    }

    const handleDelete = async () => {
        showSweetAlert('warning', `Are you sure you want to delete stored labels?`, () => { handleDeleteCall() });
    }

    const handleDeleteCall = async () => {
        try {
            const url = `label/deleteall?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`
            const response = await fetch(url, { method: 'delete' });

            if (response.status == 200) {
                showSweetAlert('success');
                setIsLoading(true);
                setLabels();
            }
        }
        catch (e) {
            showSweetAlert('serverError');
        }
    }
       
    // ### HOOKS ###
    useEffect(() => {
        fetchLabels();
    }, [])
    
    return (
        <>
            {getPermission(user.permissions, LABELS_VIEW) &&
                <Container fluid>
                        <Row>
                            <Col md="12">
                                <LabelsTable
                                    isLoading={isLoading}
                                    data={labels}
                                    handleDelete={handleDelete}
                                    handlePrintLabel={handlePrintLabel}                                    
                                />
                            </Col>
                        </Row>
                </Container>
            }
            {!getPermission(user.permissions, LABELS_VIEW) &&
                <Container className="mt-4 ms-4">You are not authorized to view this page</Container>
            }
        </>
    );
}
export default Labels;