import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Card,
  Container,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";

const SpecialOrderNotes = (props) => {
  const returnUser = (id) => {
    const user = props.users.find((x) => x.id === id);
    if (user) {
      return user.firstName + " " + user.lastName;
    }
    return "";
  };

  const renderTableData = () => {
    return (
      props.data &&
      props.data?.length > 0 &&
      props.data.map((log, index) => {
        const { note, userId, id, dateCreated } = log;
        const createdDate = new Date(dateCreated);
        return (
          <tr key={index}>
            <td>{note}</td>
            <td>
              {userId === 0
                ? "System"
                : returnUser(userId) === "Admin Admin"
                ? "Admin"
                : returnUser(userId)}
            </td>
            <td>{createdDate.toLocaleString()}</td>
            <td>
              <Button
                className="btn-fill float-end"
                variant="primary"
                disabled={props.user.id !== userId}
                onClick={() => props.deleteSpecialOrderNote(id)}
              >
                Delete
              </Button>
              <Button
                className="btn-fill float-end"
                variant="primary"
                disabled={props.user.id !== userId}
                onClick={() => toggleEditSpecialOrderNote(id)}
              >
                Edit
              </Button>
            </td>
          </tr>
        );
      })
    );
  };

  const toggleEditSpecialOrderNote = (id) => {
    props.setSelectedNote(
      props.data.find((note) => {
        return note.id === id;
      })
    );
    props.setIsEditNote(!props.isEdit);
  };

  const handleCancelEditSpecialOrderNote = () => {
    props.setSelectedNote(null);
    props.setIsEditNote(!props.isEdit);
  };

  const returnInput = () => {
    if (props.isEditNote) {
      return (
        <InputGroup className="mb-3" style={{ height: "100px" }}>
          <FormControl
            className="h-100"
            as="textarea"
            value={props.selectedNote.note}
            onChange={(e) =>
              props.setSelectedNote({
                ...props.selectedNote,
                note: e.target.value,
              })
            }
          />
        </InputGroup>
      );
    } else if (props.isAddNote) {
      return (
        <InputGroup className="pe-3 ps-3" style={{ height: "100px" }}>
          <FormControl
            className="h-100"
            as="textarea"
            value={props.selectedNote?.note}
            onChange={(e) =>
              props.setSelectedNote({
                note: e.target.value,
                id: 0,
                specialOrderId: props.orderId,
                userId: props.user.id,
              })
            }
          />
        </InputGroup>
      );
    } else {
      return null;
    }
  };

  return (
    <Card>
      <Card.Header>Order Notes</Card.Header>
      <Card.Body>
        <Table className="table-hover table-striped w-full">
          {props.data?.length === 0 && !props.isAddNote ? (
            <div className="text-center">
              <h4>No Notes</h4>
            </div>
          ) : props.isEditNote || props.isAddNote ? (
            returnInput()
          ) : (
            <>
              <thead>
                <tr>
                  <th>Note</th>
                  <th>Created by</th>
                  <th>Created Date</th>
                  <th className="d-flex justify-content-end align-items-end">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </>
          )}
        </Table>
        {!props.isEditNote &&
          (props.isAddNote ? null : (
            <Button onClick={props.handleAddSpecialOrderNote}>Add Note</Button>
          ))}
      </Card.Body>
    </Card>
  );
};

export default SpecialOrderNotes;
