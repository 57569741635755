import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
  Form,
  Dropdown,
  Navbar,
  Nav,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import CustomSearchBox from "components/Shared/CustomSearchBox";
import CustomSelect from "../../components/Shared/CustomSelect";

const FulfillmentOrderLinesTable = (props) => {
  const returnLocationName = (locationId) => {
    const locationName = props.locations.find(
      (location) => location.id === parseInt(locationId)
    )?.value;
    return locationName;
  };

  const returnFulfilmentCards = () => {
    return (
      props.fulfillmentOrderLines &&
      props.fulfillmentOrderLines.length > 0 &&
      props.fulfillmentOrderLines.map((item, index) => {
        return (
          <Card key={item.id} className="location-card my-3">
            <Card.Header className="d-flex justify-content-between">
              <Card.Title as="h4">
                {returnLocationName(item.locationId)}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Table className="table-hover table-striped w-full">
                <thead>
                  <tr>
                    <th></th>
                    <th>SKU</th>
                    <th>Fulfillment Status</th>
                    <th>Quantity</th>
                    <th>Colour</th>
                    <th>Size</th>
                    <th>Name</th>
                    <th>Brandcode</th>
                    <th>Barcode</th>
                    <th>Price</th>
                    <th>SubTotal</th>
                    <th>Total Tax</th>
                    <th>Picked Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {item.fulfillmentLines.map((line, index) => {
                    return (
                      <tr key={line.id}>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                type="checkbox"
                                // checked={props.checkedLineItems[item.id] || false}
                                checked={
                                  props.checkedLineItems.some(
                                    (x) => x.id === line.id
                                  ) || false
                                }
                                onChange={() =>
                                  props.handleChangeFulfillmentOrderLocation(
                                    line
                                  )
                                }
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>
                          <a
                            href="#id"
                            onClick={() =>
                              props.handleGetItemCard(line.productId)
                            }
                          >
                            {line.SKU}
                          </a>
                        </td>
                        <td>{line.fulfillmentStatus}</td>
                        <td>
                          {/* todo - add onchange for the quantity thats being changed  */}
                          {!props.checkedLineItems.some(
                            (x) => x.id === line.id
                          ) ? (
                            line.quantity
                          ) : (
                            <>
                              <InputGroup>
                                <InputGroup.Text>
                                  <i
                                    className="fas fa-minus"
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  max={line.quantity}
                                  min={1}
                                  disabled
                                  value={`${line.quantity} / Max: ${
                                    line.quantity * 2
                                  }`}
                                ></Form.Control>
                                <InputGroup.Text>
                                  <i
                                    className="fas fa-plus"
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                </InputGroup.Text>
                              </InputGroup>
                            </>
                          )}
                        </td>
                        <td>{line.colour}</td>
                        <td>{line.size}</td>
                        <td>{line.name}</td>
                        <td>{line.brandcode}</td>
                        <td>{line.barcode}</td>
                        <td>£{line.price?.toFixed(2)}</td>
                        <td>£{line.subTotal?.toFixed(2)}</td>
                        <td>£{line.totalTax?.toFixed(2)}</td>
                        <td>
                          <Form>
                            <Form.Group>
                              <CustomSelect
                                value={line.statusId ?? 4}
                                data={props.pickedStatusDropdownOptions}
                                handleChange={(e) =>
                                  props.handlePickedStatusChange(
                                    e.value,
                                    line,
                                    item
                                  )
                                }
                              />
                            </Form.Group>
                          </Form>
                        </td>
                        <td className="td-actions text-right d-flex justify-content-end align-items-end">
                          <Button
                            disabled={line.fulfillmentStatus !== "pending"}
                            className="btn-link btn-xs btn-action"
                            variant="danger"
                            onClick={() =>
                              props.handleShowEditOrderLocationModal(line)
                            }
                          >
                            <i
                              className="fas fa-edit"
                              style={{
                                opacity:
                                  line.fulfillmentStatus === "pending" ? 1 : 0,
                              }}
                            ></i>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
            {returnActionButtons(item)}
          </Card>
        );
      })
    );
  };

  // placeholder for now until endpoints up
  const [selectedLocations, setSelectedLocations] = useState([]);
  const handleSelectedLocationChange = (e, item) => {
    const newLocation = {
      locationId: e,
      itemId: item.id,
    };

    setSelectedLocations((prevState) => {
      const index = prevState.findIndex((x) => x.itemId === item.id);

      if (index !== -1) {
        // Update existing location
        const updatedLocations = [...prevState];
        updatedLocations[index] = newLocation;
        return updatedLocations;
      } else {
        // Add new location
        return [...prevState, newLocation];
      }
    });

    // const locations = selectedLocations || [];
    // const index = locations.findIndex((x) => x.itemId === item.id);
    // if (index !== -1) {
    //   const index = selectedLocations.findIndex((x) => x.itemId === item.id);
    //   const updatedLocations = [...selectedLocations];
    //   updatedLocations[index] = newLocation;
    //   setSelectedLocations(updatedLocations);
    //   return;
    // }
    // setSelectedLocations([...selectedLocations, newLocation]);
    // console.log("selected locations ", selectedLocations);
  };

  const returnActionButtons = (item) => {
    // only show if location id is in checkedLineItems
    // if (!props.checkedLineItems.some((x) => x.id === parseInt(item.id))) return;
    if (
      !item.fulfillmentLines.some((line) =>
        props.checkedLineItems.some((checkedItem) => checkedItem.id === line.id)
      )
    ) {
      return null;
    }

    // todo - on setting selected location - assign the associated line items to that location for the call
    if (props.isChangeFulfillmentOrderLocation) {
      return (
        <Row className="d-flex justify-content-between pb-3">
          <Col xs={3}>
            <h5>Change Location</h5>
            <Form>
              <Form.Group>
                <CustomSelect
                  placeholder="Location"
                  value={
                    selectedLocations.find((x) => x.itemId === item.id)
                      ?.locationId
                  }
                  data={props.locations}
                  handleChange={(e) =>
                    handleSelectedLocationChange(e.value, item)
                  }
                />
              </Form.Group>
            </Form>
          </Col>
          <Col className="d-flex justify-content-end pt-3">
            <Button
              variant="primary"
              onClick={() =>
                props.handleCancelChangeFulfillmentOrderLocation(true)
              }
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                props.handleConfirmChangeFulfillmentOrderLocation(
                  item,
                  selectedLocations
                )
              }
            >
              Change Location
            </Button>
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title as="h4">Order Lines</Card.Title>
        </Card.Header>
        <Card.Body className="table-responsive p-0">
          {props.isLoading ? (
            <div
              style={{ height: 120 }}
              className="d-flex justify-content-center align-items-center text-center"
            >
              <div>
                <Spinner animation="border" />
                <p>Loading...</p>
              </div>
            </div>
          ) : (
            <>
              {props.data && props.data.length == 0 && (
                <p
                  style={{ height: 120 }}
                  className="d-flex justify-content-center align-items-center text-center"
                >
                  No data found.
                </p>
              )}
            </>
          )}
          {props.data?.length !== 0 && !props.isLoading && (
            <Col md="20">
              <Table className="table-hover table-striped w-full">
                <tbody>{returnFulfilmentCards()}</tbody>
              </Table>
            </Col>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default FulfillmentOrderLinesTable;
