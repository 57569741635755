import React, { useEffect, useState } from "react";
import CustomTable from "components/Shared/CustomTable";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
  Form,
  CardHeader,
} from "react-bootstrap";
import CustomSelect from "components/Shared/CustomSelect";
import CustomAutoComplete from "components/Shared/CustomAutoComplete";

function CreateStocktakeModal(props) {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "650px");
  }, []);

  var hasPermission = false;
  var permissions = localStorage.getItem("permissions");

  return (
    <>
      {alert}
      <Container fluid className="PurchaseModal">
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Create Stocktake</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group>
                    <label>Location</label>
                    <CustomSelect
                      placeholder="Location"
                      data={props.locationDropdown}
                      id={props.newStocktake.locationId}
                      handleChange={(e) => {
                        let item = {
                          ...props.newStocktake,
                          locationId: e.value,
                        };
                        props.setNewStocktake(item);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <label>Brands</label>
                    <CustomAutoComplete
                      items={props.brandsDropdown}
                      selectedItems={props.newStocktake.brandIds}
                      label="Add Brands"
                      handleAddItem={(item) => {
                        const newItem = {
                          id: item.value,
                          value: item.label,
                        };
                        props.setNewStocktake({
                          ...props.newStocktake,
                          brandIds: [...props.newStocktake.brandIds, newItem],
                        });
                      }}
                      handleRemoveItem={(item) => {
                        props.setNewStocktake({
                          ...props.newStocktake,
                          brandIds: [
                            ...props.newStocktake.brandIds.filter(
                              (i) => i.id !== item.id
                            ),
                          ],
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <label>Gender</label>
                    <CustomSelect
                      placeholder="Gender"
                      id={props.newStocktake.genderId}
                      data={props.gendersDropdown}
                      handleChange={(e) => {
                        let item = {
                          ...props.newStocktake,
                          genderId: e.value,
                        };
                        props.setNewStocktake(item);
                      }}
                      withAll={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <div className="d-flex w-100 justify-content-end">
                <Button
                  className="btn-fill"
                  variant="primary"
                  onClick={() => {
                    props.handleCreateStocktake();
                  }}
                >
                  Create
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Container>
    </>
  );
}
export default CreateStocktakeModal;
