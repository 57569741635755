import React, { useEffect } from "react";
import { Card, Col, Container, Table, Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

const StocktakeAnalysisModal = (props) => {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "1000px");
  }, []);

  const returnTable = () => {
    return (
      props.data &&
      props.data.map((item, index) => {
        const {
          productId,
          productName,
          brandCode,
          colour,
          size,
          barcode,
          sku,
          lastScannedDate,
        } = item;

        const date = new Date(lastScannedDate);
        const formattedDate = date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        });

        return (
          <tr key={index}>
            <td>{sku}</td>
            <td>{productName}</td>
            <td>{brandCode}</td>
            <td>{colour}</td>
            <td>{size}</td>
            <td>{barcode}</td>
            <td>{formattedDate}</td>
          </tr>
        );
      })
    );
  };

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title
            as="h4"
            className="d-flex justify-content-between align-items-center"
          >
            Stocktake Analysis
          </Card.Title>
        </Card.Header>
        {/* todo - if there is data BUT its also 0 scanned then show diff message */}
        <Card.Body className="table-responsive p-0">
          {props.data && props.data.length == 0 && !props.isLoading && (
            <p
              style={{ height: 550 }}
              className="d-flex justify-content-center align-items-center text-center"
            >
              No data found.
            </p>
          )}
          {props.data && props.data.length > 0 && !props.isLoading && (
            <Col md="12">
              <InfiniteScroll
                dataLength={props.data.length}
                next={props.updateData}
                hasMore={true}
                height={650}
                loader={
                  props.data.length >= props.take ? (
                    <h6>Loading more data...</h6>
                  ) : (
                    ""
                  )
                }
              >
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th>Name</th>
                      <th>Brand Code</th>
                      <th>Colour</th>
                      <th>Size</th>
                      <th>Barcode</th>
                      <th>Last Scanned</th>
                    </tr>
                  </thead>
                  <tbody>{returnTable()}</tbody>
                </Table>
              </InfiniteScroll>
            </Col>
          )}
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end"></Card.Footer>
      </Card>
    </>
  );
};

export default StocktakeAnalysisModal;
