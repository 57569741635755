import React from "react";
import { useState } from "react";
import moment from "moment";

// react-bootstrap components
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
} from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";

function LabelsTable(props) {
  // ### STATE ###
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <Card.Title
          as="h4"
          className="d-flex justify-content-between align-items-center"
        >
          <div>Labels</div>
          <div>
            <Button
              className="btn-fill mr-2" // Added margin-right for spacing between buttons
              variant="primary"
              onClick={() => props.handleDelete()}
            >
              Delete Labels
            </Button>
            <Button
              className="btn-fill"
              variant="primary"
              onClick={(e) => props.handlePrintLabel()}
            >
              Print Labels
            </Button>
          </div>
        </Card.Title>

        <Card.Body className="table-responsive p-0">
          {props.isLoading ? (
            // Render loading indicator or similar content
            <div
              style={{ height: 550 }}
              className="d-flex justify-content-center align-items-center text-center"
            >
              <div>
                <Spinner animation="border" />
                <p>Loading...</p>
              </div>
            </div>
          ) : props.data && props.data.length === 0 ? (
            // Render no labels found message
            <p
              style={{ height: 550 }}
              className="d-flex justify-content-center align-items-center text-center"
            >
              No labels found.
            </p>
          ) : (
            // Render the table
            <Col md="12">
              <Table className="table-hover table-striped w-full">
                <thead>
                  <tr>
                    <th>Product</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data.map((label, index) => (
                    <tr key={`Labels${index}`}>
                      <td>{label.productDescription}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default LabelsTable;
