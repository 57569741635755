import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { showSweetAlert } from "components/Shared/CustomAlert";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import DateRange from "../../components/Shared/DateRange";
import CustomSelect from "../../components/Shared/CustomSelect";
import CustomSearchBox from "../../components/Shared/CustomSearchBox";
import AuditLogTable from "../../components/AuditLog/AuditLogTable";

import { AUDITLOG_VIEW } from "constants/Permissions.js";
import { getPermission } from "utils/Permissions.js";

function AuditLog() {
  const user = useSelector((state) => state.user);

  // ### STATE ###

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(20);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState(0);
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState(0);
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState("Timestamp");
  const [descending, setDescending] = useState(true);
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);

  // ### HANDLERS ###

  const handleDatesChange = (dates) => {
    setDateRange(dates);
    if (dates[0] && dates[1]) {
      const filterData = originData.filter((item) => {
        return moment(item.timestamp).isBetween(
          moment(dates[0], "YYYY-MM-DDTHH:mm:ssZ").format(
            "YYYY-MM-DDTHH:mm:ssZ"
          ),
          moment(dates[1], "YYYY-MM-DDTHH:mm:ssZ").format(
            "YYYY-MM-DDTHH:mm:ssZ"
          ),
          null,
          "[]"
        );
      });
      setData(filterData);
    }
  };

  const handlePermission = () => {
    showSweetAlert("unauthorized");
  };

  const hideAlert = () => {};

  // ### DATA FETCHES ##

  const fetchUsers = async () => {
    try {
      let url = `/user/getalllist?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url);
      const body = await response.json();
      const data = body?.map((item) => {
        return { id: item.id, value: item.name };
      });
      setUsers(data);
    } catch (err) {}
  };

  const fetchTypes = async () => {
    try {
      let url = `/auditlog/gettypes`;
      const response = await fetch(url);
      const body = await response.json();
      const keys = Object.keys(body);
      const data = keys?.map((item) => {
        return { id: item, value: body[item] };
      });
      setTypes(data);
    } catch (err) {}
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let url = `/auditlog/getallpaged?skip=${skip}&take=${take}&searchUserId=${username}&typeId=${type}&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem(
        "userToken"
      )}&orderBy=${orderBy}&descending=${descending}&search=${search}`;
      const response = await fetch(url);
      const body = await response.json();
      setIsLoading(false);
      setOriginData(body);
      setData(body);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const updateData = async () => {
    try {
      let url = `/auditlog/getallpaged?skip=${
        page * take + skip
      }&take=${take}&searchUserId=${username}&typeId=${type}&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem(
        "userToken"
      )}&orderBy=${orderBy}&descending=${descending}&search=${search}`;
      const response = await fetch(url);
      const body = await response.json();
      setTimeout(() => {
        setOriginData(originData.concat(body));
        setData(data.concat(body));
        setPage(page + 1);
      }, 500);
    } catch (err) {}
  };

  // ### HOOKS ###

  useMemo(async () => {
    setPage(0);
    setOriginData([]);
    setData([]);
    setIsLoading(true);
    try {
      let url = `/auditlog/getallpaged?skip=${0}&take=${20}&searchUserId=${username}&typeId=${type}&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem(
        "userToken"
      )}&orderBy=${orderBy}&descending=${descending}&search=${search}`;
      const response = await fetch(url);
      const body = await response.json();
      setIsLoading(false);
      setOriginData(body);
      setData(body);
    } catch (err) {
      setIsLoading(false);
    }
  }, [type, username, search]);

  useEffect(() => {
    fetchUsers();
    fetchTypes();
    fetchData();
  }, []);

  return (
    <>
      {alert}
      {getPermission(user.permissions, AUDITLOG_VIEW) && (
        <Container fluid>
          <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md="3">
                        <DateRange
                          title="Date"
                          startDate={dateRange[0]}
                          endDate={dateRange[1]}
                          handleChange={handleDatesChange}
                          range={true}
                        />
                      </Col>
                      <Col md="3">
                        <CustomSelect
                          title="Type"
                          placeholder="Type"
                          id={type}
                          data={types}
                          handleChange={(e) => setType(e.value)}
                          withAll={true}
                        />
                      </Col>
                      <Col md="3">
                        <CustomSelect
                          title="Username"
                          placeholder="Username"
                          id={username}
                          data={users}
                          handleChange={(e) => setUsername(e.value)}
                          withAll={true}
                        />
                      </Col>
                      <Col md="3">
                        <CustomSearchBox
                          title="Search"
                          placeholder="Search"
                          value={search}
                          handleChange={(e) => setSearch(e)}
                          handleClear={() => setSearch("")}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

          <Container fluid>
            <Row>
              <Col md="12">
                <AuditLogTable
                  isLoading={isLoading}
                  data={data}
                  updateData={updateData}
                />
              </Col>
            </Row>
          </Container>
        </Container>
      )}
      {!getPermission(user.permissions, AUDITLOG_VIEW) && (
        <Container className="mt-4 ms-4">
          You are not authorized to view this page
        </Container>
      )}
    </>
  );
}

export default AuditLog;
