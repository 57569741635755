import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { showSweetAlert } from "components/Shared/CustomAlert";
import CustomSearchBox from "components/Shared/CustomSearchBox";
import { useDispatch } from "react-redux";

import { formatCurrency } from "utils/FormatCurrency.js";
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import { PURCHASEORDER_EDIT } from "constants/Permissions.js";
import { getPermission } from "utils/Permissions.js";

function PurchaseOrderTable(props) {
  const SearchStyle = {
    marginTop: "-35px",
    marginLeft: "190px",
  };
  // ### STATE ###
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handlePermission = () => {
    showSweetAlert("unauthorized");
  };

  const convertDate = (date) => {
    const dateTime = new Date(date);
    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = dateTime.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // ### renderTableData ###
  const renderTableData = () => {
    return props.data.map((log, index) => {
      const {
        purchaseOrderNumber,
        brandName,
        userFullName,
        costPrice,
        total,
        id,
        createdDate,
        season,
        statusName,
        statusId,
        totalQuantity,
        totalAllocated,
        locationName,
      } = log;
      if (statusId == 0) {
        return (
          <tr key={`PurchaseOrder${index}`}>
            <td>{purchaseOrderNumber}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Processing...</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        );
      } else {
        return (
          <tr key={`PurchaseOrder${index}`}>
            <td onClick={() => props.PurchaseOrderLines(id)}>
              <a href="#id">{purchaseOrderNumber}</a>
            </td>
            <td>{locationName}</td>
            <td>{brandName}</td>
            <td>{userFullName}</td>
            <td>{convertDate(createdDate)}</td>
            <td>{formatCurrency(costPrice)}</td>
            <td>{season}</td>
            <td>{statusName}</td>
            <td>
              {totalQuantity} ({totalAllocated})
            </td>
            <td>{formatCurrency(total)}</td>
            <td>
              <OverlayTrigger
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                overlay={
                  <Tooltip id="tooltip-255158527">
                    Download Excel Export
                  </Tooltip>
                }
              >
                <Button
                  href="#"
                  onClick={(e) =>
                    props.handleGetExportExcel(id, purchaseOrderNumber)
                  }
                  variant="link"
                >
                  <i className="fa fa-download"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                overlay={
                  <Tooltip id="tooltip-255158527">Download PDF Export</Tooltip>
                }
              >
                <Button
                  href="#"
                  onClick={(e) =>
                    props.handleGetExportPDF(id, purchaseOrderNumber)
                  }
                  variant="link"
                >
                  <i className="fa fa-file"></i>
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                overlay={
                  <Tooltip id="tooltip-255158527">Download Labels PDF</Tooltip>
                }
              >
                <Button
                  href="#"
                  onClick={(e) =>
                    props.handleGetLabelPDF(id, purchaseOrderNumber)
                  }
                  variant="link"
                >
                  <i className="fa fa-tag"></i>
                </Button>
              </OverlayTrigger>
              {getPermission(user.permissions, PURCHASEORDER_EDIT) && (
                <>
                  {statusId === 1 || statusId === 2 ? (
                    totalAllocated === 0 ? (
                      <Button
                        className="fa-sharp fa-light fa-arrow-down-to-line"
                        onClick={() => props.handleDeletePurchaseOrder(id)}
                      >
                        Delete
                      </Button>
                    ) : (
                      <Button
                        className="fa-sharp fa-light fa-arrow-down-to-line"
                        onClick={() => props.handleClosePurchaseOrder(id)}
                      >
                        Close
                      </Button>
                    )
                  ) : statusId === 4 ? (
                    <Button
                      className="fa-sharp fa-light fa-arrow-down-to-line"
                      onClick={() => props.handleReopenPurchaseOrder(id)}
                    >
                      Reopen
                    </Button>
                  ) : null}
                </>
              )}
            </td>
          </tr>
        );
      }
    });
  };

  return (
    <>
      {alert}
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title as="h4">Purchase Order</Card.Title>
          <p className="card-category"></p>
          {/*<Col md="3" style={SearchStyle}>*/}
          {/*    <CustomSearchBox*/}
          {/*        placeholder="Search"*/}
          {/*        value={props.search}*/}
          {/*        handleChange={(e) => props.handleSearchChange(e)}*/}
          {/*        handleClear={() => props.handleSearchChange("")}*/}
          {/*    />*/}
          {/*</Col>*/}
        </Card.Header>
        <Card.Body className="table-responsive p-0">
          {props.isLoading ? (
            <div
              style={{ height: 550 }}
              className="d-flex justify-content-center align-items-center text-center"
            >
              <div>
                <Spinner animation="border" />
                <p>Loading...</p>
              </div>
            </div>
          ) : (
            <>
              {props.data && props.data.length == 0 && (
                <p
                  style={{ height: 550 }}
                  className="d-flex justify-content-center align-items-center text-center"
                >
                  No data found.
                </p>
              )}
            </>
          )}
          {props.data && props.data.length > 0 && !props.isLoading && (
            <Col md="12">
              <InfiniteScroll
                dataLength={props.data.length}
                next={props.updateData}
                hasMore={true}
                //loader={props.data.length >= 20 ? <h6>Loading more data...</h6> : ""}
                loader=""
                height={650}
              >
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>PO#</th>
                      <th>To</th>
                      <th>Brand</th>
                      <th>Created By</th>
                      <th>Created On</th>
                      <th>Cost Price</th>
                      <th>Season</th>
                      <th>Status</th>
                      <th>Units</th>
                      <th>Total</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableData()}</tbody>
                </Table>
              </InfiniteScroll>
            </Col>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default PurchaseOrderTable;
