import React, { useState, useEffect, useMemo } from "react";
import StocktakeLinesTable from "components/Stocktake/StocktakeLinesTable";
import StocktakeLineModal from "components/Stocktake/StocktakeLineModal";
import StocktakeTable from "components/Stocktake/StocktakeTable";
import CreateStocktakeModal from "components/Stocktake/CreateStocktakeModal";
import StocktakeAnalysisModal from "components/Stocktake/StocktakeAnalysisModal";
import ApproveAdjustmentsModal from "components/Stocktake/ApproveAdjustmentsModal";
import { Container, Row, Col, Card } from "react-bootstrap";
import CustomModal from "../../components/Shared/CustomModal";
import DateRange from "../../components/Shared/DateRange";
import CustomSelect from "components/Shared/CustomSelect";
import { showSweetAlert } from "components/Shared/CustomAlert";
import WebOrdersModal from "components/ItemCard/WebOrdersModal";

const Stocktake = () => {
  // stock take data
  const [stocktakeData, setStockTakeData] = useState([]);
  const [selectedStocktakeId, setSelectedStocktakeId] = useState(null);
  const [selectedStocktake, setSelectedStocktake] = useState(null);
  const [selectedStocktakeStatus, setSelectedStocktakeStatus] = useState(null);
  const [selectedStocktakeLocationId, setSelectedStocktakeLocationId] =
    useState(null);

  // dropdown states
  const [locationDropdown, setLocationDropdown] = useState([]);
  const [brandsDropdown, setBrandsDropdown] = useState([]);
  const [statusId, setStatusId] = useState(0);
  const [genderId, setGenderId] = useState(0);
  const [gendersDropdown, setGendersDropdown] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [users, setUsers] = useState([]);

  // stocktake lines states
  const [checkedItems, setCheckedItems] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const selectAll = (data) => {
    const newCheckedItems = {};
    selectedStocktake.forEach((stocktake) => {
      newCheckedItems[stocktake.stocktakeId] = !allChecked;
    });
    setCheckedItems(newCheckedItems);
    setAllChecked(!allChecked);
  };

  // user selected location and brand ids for filtering
  const [locationId, setLocationId] = useState(0);
  const [brandId, setBrandId] = useState(0);

  // stock take line modal
  const [showStocktakeLinesModal, setShowStocktakeLinesModal] = useState(false);
  const handleShowStocktakeLinesModal = () => setShowStocktakeLinesModal(true);
  const handleCloseStocktakeLinesModal = () => {
    setSelectedStocktake(null);
    setShowStocktakeLinesModal(false);
  };

  // create modal
  const [showCreateStocktakeModal, setShowCreateStocktakeModal] =
    useState(false);
  const handleShowCreateStocktakeModal = () => {
    setShowCreateStocktakeModal(true);
  };
  const handleCloseCreateStocktakeModal = () => {
    setNewStocktake({
      locationId: "",
      brandIds: [],
    });
    setShowCreateStocktakeModal(false);
  };

  // todo - Stocktake Analysis Modal - Once endpoint live
  const [showStocktakeAnalysisModal, setShowStocktakeAnalysisModal] =
    useState(false);
  const [stocktakeAnalysisData, setStocktakeAnalysisData] = useState([]);
  const handleShowStocktakeAnalysisModal = async () => {
    await fetchStocktakeAnalysisData();
    setShowStocktakeAnalysisModal(true);
  };

  const handleCloseStocktakeAnalysisModal = () => {
    setShowStocktakeAnalysisModal(false);
  };

  const fetchStocktakeAnalysisData = async () => {
    const userId = localStorage.getItem("userId");
    const userToken = localStorage.getItem("userToken");
    const url = `/Stocktake/GetLastScannedList?userId=${userId}&userToken=${userToken}&locationId=${locationId}`;
    const response = await fetch(url);
    const body = await response.json();
    setStocktakeAnalysisData(body);
  };

  // approve adjustments modal
  const [showApproveAdjustmentsModal, setShowApproveAdjustmentsModal] =
    useState(false);  
  const [stocktakeConfirming, setStocktakeConfirming] =
    useState(false);
  const handleShowApproveAdjustmentsModal = () => {
    /**
                 * todo
                  If FULL stock take (we can add a flag to the Db for this as well isFullStocktake), 
                  then we also need an extra message of "As a full stocktake, your accuracy rate is xx%" 
                  within the popup message.
                 */
    setShowStocktakeLinesModal(false);
    setShowApproveAdjustmentsModal(true);
  };
  const [hasConsentedToAdjustments, setHasConsentedToAdjustments] =
    useState(false);
  const handleCloseApproveAdjustmentsModal = () => {
    setShowStocktakeLinesModal(true);
    setHasConsentedToAdjustments(false);
    setShowApproveAdjustmentsModal(false);
    setAdjustmentCalculationsData(null);
    setAdjustmentNotes("");
  };
  const handleConsentApproveAdjustments = () => {
    setHasConsentedToAdjustments(!hasConsentedToAdjustments);
  };

  // on creating new stock take this will be populated and sent to serv
  const [newStocktake, setNewStocktake] = useState({
    locationId: "",
    brandIds: [],
    genderId: "",
  });

  // track when an adjustment has been made
  const [adjustmentMade, setAdjustmentMade] = useState(false);
  const [adjustmentCalculationsData, setAdjustmentCalculationsData] =
    useState();

  const handleAdjustTotal = (stocktakeLineId, totalStock) => {
    setSelectedStocktake((prev) => {
      return prev.map((line) => {
        if (line.id === stocktakeLineId) {
          return {
            ...line,
            totalStock: totalStock,
          };
        }
        return line;
      });
    });
  };

  const defaultDate = new Date();
  const [dateRange, setDateRange] = useState([
    defaultDate.setMonth(defaultDate.getMonth() - 3),
    new Date(),
  ]);

  // fetch initial data
  useEffect(() => {
    fetchLocationDropdown();
    fetchStatusDropdown();
    fetchBrandDropdownList();
    fetchGenderDropdownList();
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchStockTakeData();
  }, [dateRange, locationId, brandId, statusId, genderId]);

  const fetchUsers = async () => {
    try {
      let url = `/user/getalllist?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url);
      const body = await response.json();
      const data = body?.map((item) => {
        return { id: item.id, value: item.name };
      });
      setUsers(data);
    } catch (err) {}
  };

  const handleDatesChange = (dates) => {
    setDateRange(dates);
    if (dates[0] && dates[1]) {
      const filterData = originData.filter((item) => {
        return moment(item.timestamp).isBetween(
          moment(dates[0], "YYYY-MM-DDTHH:mm:ssZ").format(
            "YYYY-MM-DDTHH:mm:ssZ"
          ),
          moment(dates[1], "YYYY-MM-DDTHH:mm:ssZ").format(
            "YYYY-MM-DDTHH:mm:ssZ"
          ),
          null,
          "[]"
        );
      });
      setfilterDateData(filterData);
      localStorage.setItem("dateRange", filterData);
    }
  };

  const fetchBrandDropdownList = async () => {
    try {
      let brandUrl = `/CategoryValue/GetDropdownList?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&categoryId=2`;
      const brandResponse = await fetch(brandUrl);
      const brandBody = await brandResponse.json();
      const brandData = brandBody?.map((item) => {
        return { id: item.id, value: item.name };
      });
      setBrandsDropdown(brandData);
    } catch (err) {}
  };

  const fetchGenderDropdownList = async () => {
    try {
      let genderUrl = `/CategoryValue/GetDropdownList?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&categoryId=1`;
      const genderResponse = await fetch(genderUrl);
      const genderBody = await genderResponse.json();
      const genderData = genderBody?.map((item) => {
        return { id: item.id, value: item.name };
      });
      setGendersDropdown(genderData);
    } catch (err) {}
  };

  const fetchLocationDropdown = async () => {
    try {
      let url = `/location/GetDropdownList?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url);
      const locations = await response.json();

      setLocationDropdown(
        locations?.map((item) => {
          return { id: item.id, value: item.name };
        })
      );
    } catch (err) {}
  };

  const fetchStatusDropdown = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const userToken = localStorage.getItem("userToken");
      const url = `/Stocktake/GetStatusDropdownList?userId=${userId}&userToken=${userToken}`;
      const response = await fetch(url);
      const statuses = await response.json();

      setStatusDropdown(
        statuses?.map((status) => {
          return { id: status.id, value: status.name };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchStockTakeData = async () => {
    const fromDate = new Date(dateRange[0]).toISOString();
    const toDate = new Date(dateRange[1]).toISOString();

    try {
      let url = `/Stocktake/GetAll?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem(
        "userToken"
      )}&locationId=${locationId}&brandId=${brandId}&genderId=${genderId}&statusId=${statusId}&from=${fromDate}&to=${toDate}`;

      const response = await fetch(url);
      const data = await response.json();
      setStockTakeData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStocktakeLines = async (stocktakeId) => {
    try {
      let url = `/Stocktake/GetLines?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem(
        "userToken"
      )}&stocktakeId=${stocktakeId}`;

      const response = await fetch(url);
      const data = await response.json();
      setSelectedStocktakeId(stocktakeId);
      setSelectedStocktake(data);
      setSelectedStocktakeStatus(
        stocktakeData.find((s) => s.id == stocktakeId).status
      );
      setSelectedStocktakeLocationId(
        stocktakeData.find((s) => s.id == stocktakeId).locationId
      );
      handleShowStocktakeLinesModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteStocktake = async (stocktakeId) => {
    showSweetAlert(
      "warning",
      "Are you sure you want to delete this stocktake?",
      () => {
        confirmDeleteStocktake(stocktakeId);
      }
    );
  };

  const confirmDeleteStocktake = async (stocktakeId) => {
    try {
      let url = `/Stocktake/Delete?id=${stocktakeId}&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;

      const response = await fetch(url, {
        method: "DELETE",
      });

      if (response.status === 200) {
        fetchStockTakeData();
      } else {
        showSweetAlert("serverError");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateStocktake = async () => {
    // validate all new fields are populated
    if (
      newStocktake.locationId === "" ||
      newStocktake.genderId === "" ||
      newStocktake.brandIds.length === 0
    ) {
      showSweetAlert("warningOkOnly", "Please fill in all fields.");
      return;
    }
    confirmCreateStocktake();
  };

  const confirmCreateStocktake = async () => {
    // search the ids to check for 0 e.g. with all and send empty array
    if (newStocktake.brandIds.length > 0) {
      const containsAll = newStocktake.brandIds.find((item) => item.id === 0);
      if (containsAll) {
        newStocktake.brandIds = [];
      }
    }

    try {
      let url = `/Stocktake/Add?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&locationId=${
        newStocktake.locationId
      }&genderId=${newStocktake.genderId}`;

      const brandIds = newStocktake.brandIds.map((item) => item.id);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(brandIds),
      });

      if (response.status === 200) {
        showSweetAlert("saveSuccessCustomMessage", "Stocktake created");
        fetchStockTakeData();
      } else {
        const data = await response.json();
        showSweetAlert("errorCustomMessage", data);
      }
      
      handleCloseCreateStocktakeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmStocktake = async (stocktakeId) => {
    if (stocktakeId === null) return;
    // make api call for the calculations
    await handleFetchConfirmationCalculations(stocktakeId);

    handleShowApproveAdjustmentsModal();
  };

  const handleFetchConfirmationCalculations = async (stocktakeId) => {
    try {
      let url = `/Stocktake/GetCompletionConfirmation?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem(
        "userToken"
      )}&stocktakeId=${stocktakeId}`;

      const response = await fetch(url, {
        method: "POST",
      });
      const data = await response.json();

      setAdjustmentCalculationsData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [adjustmentNotes, setAdjustmentNotes] = useState("");
  const handleConfirmApproveAdjustments = async () => {
    setStocktakeConfirming(true);
    const stocktakeId = selectedStocktake[0].stocktakeId;
    if (stocktakeId === null) return;

    try {
      let url = `/Stocktake/CompleteStocktake?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem(
        "userToken"
      )}&stocktakeId=${stocktakeId}&notes=${adjustmentNotes}`;

      const response = await fetch(url, {
        method: "POST",
      });
      const data = await response.json();

      if (response.status === 200) {
        handleCloseApproveAdjustmentsModal();
        handleCloseStocktakeLinesModal();
        fetchStockTakeData();
        showSweetAlert("saveSuccessCustomMessage", "Stocktake completed");
      }
    } catch (error) {
      console.log(error);
      showSweetAlert("errorCustomMessage", "Something went wrong saving the stocktake");
    } finally {
      setStocktakeConfirming(false);
    }
  };

  const handleSaveVariances = async () => {
    const variancesArray = selectedStocktake.map((item) => {
      return {
        stocktakeLineId: item.id,
        totalStock: item.totalStock,
      };
    });

    if (
      variancesArray === undefined ||
      variancesArray.length === 0 ||
      variancesArray[0] === null ||
      variancesArray[0] === undefined
    ) {
      return;
    }

    showSweetAlert(
      "warning",
      "Are you sure you want to save the changes?",
      () => {
        confirmSave();
      }
    );

    const confirmSave = async () => {
      try {
        let url = `/Stocktake/SaveStocktakeVariances?userId=${localStorage.getItem(
          "userId"
        )}&userToken=${localStorage.getItem("userToken")}`;

        const response = await fetch(url, {
          method: "POST",
          headers: [
            ["Content-Type", "application/json"],
            ["Content-Type", "text/plain"],
          ],
          body: JSON.stringify(variancesArray),
        });

        if (response.status === 200) {
          showSweetAlert("saveSuccess", `Adjustments saved successfully`);
          getStocktakeLines(selectedStocktakeId)
          fetchStockTakeData();
        } else {
          showSweetAlert("serverError");
        }
      } catch (error) {
        console.log(error);
        showSweetAlert("serverError");
      }
    };
  };

  const handleSelectLocation = (id) => {
    setLocationId(id);
  };

  const handleSelectBrand = (id) => {
    setBrandId(id);
  };

  const handleInTransitClick = (type, id, locationId) => {
    // display the web modal - don't navigate
    handleGetItemCardWebOrderInfo(type, id, locationId);
    setShowWebOrdersModal(true);
    setShowStocktakeLinesModal(false);
  };

  const [showWebOrdersModal, setShowWebOrdersModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [itemCardWebOrderInfo, setItemCardWebOrderInfo] = useState([]);
  const [itemCardWebOrderInfoType, setItemCardWebOrderInfoType] = useState("");
  
  const handleShowWebOrdersModal = () => {
    setShowStocktakeLinesModal(false);
    setShowWebOrdersModal(true);
  };

  const handleHideWebOrdersModal = () => {
    setShowWebOrdersModal(false);
    setShowStocktakeLinesModal(true);
  };

  const handleGetItemCardInTransitList = async (id, location) => {
    if (!id || !location) return;
    try {
      const url = `/ItemCard/GetInTransitList?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem(
        "userToken"
      )}&productId=${id}&locationId=${location}`;
      const response = await fetch(url);
      const body = await response.json();
      return body;
    } catch (err) {
      showSweetAlert("serverError");
    }
  };

  const handleGetItemCardWebOrderInfo = async (type, productId, locationId) => {
    setSelectedProductId(productId);
    // only need to show in transit for now from this view - keeping the code in case we need it later
    switch (type) {
      case "onHand":
        // do nothing
        break;
      // case "Committed":
      //   setItemCardWebOrderInfo(
      //     await handleGetItemCardCommittedList(productId, locationId)
      //   );
      //   setItemCardWebOrderInfoType("Committed");
      //   break;
      case "InTransit":
        setItemCardWebOrderInfo(
          await handleGetItemCardInTransitList(productId, locationId)
        );
        setItemCardWebOrderInfoType("In Transit");
        break;
      // case "Sold":
      //   setItemCardWebOrderInfo(
      //     await handleGetItemCardSoldList(productId, locationId)
      //   );
      //   setItemCardWebOrderInfoType("Sold");
      //   break;
      default:
        break;
    }
  };

  return (
    <>
      {/* web orders modal for in transit stocktake lines */}
      <CustomModal
        show={showWebOrdersModal}
        handleClose={handleHideWebOrdersModal}
        title=""
      >
        <WebOrdersModal
          selectedProductId={selectedProductId}
          itemCardWebOrderInfo={itemCardWebOrderInfo}
          itemCardWebOrderInfoType={itemCardWebOrderInfoType}
          handleShowWebOrdersModal={handleShowWebOrdersModal}
        />
      </CustomModal>
      {/* stock take analysis modal */}
      <CustomModal
        show={showStocktakeAnalysisModal}
        handleClose={handleCloseStocktakeAnalysisModal}
        title=""
      >
        <StocktakeAnalysisModal data={stocktakeAnalysisData} />
      </CustomModal>
      {/* approve adjustments modal */}
      <CustomModal
        show={showApproveAdjustmentsModal}
        handleClose={handleCloseApproveAdjustmentsModal}
        title=""
      >
        <ApproveAdjustmentsModal
          setHasConsentedToAdjustments={hasConsentedToAdjustments}
          hasConsentedToAdjustments={hasConsentedToAdjustments}
          handleConsentApproveAdjustments={handleConsentApproveAdjustments}
          handleConfirmApproveAdjustments={handleConfirmApproveAdjustments}
          adjustmentCalculationsData={adjustmentCalculationsData}
          adjustmentNotes={adjustmentNotes}
          setAdjustmentNotes={setAdjustmentNotes}
          stocktakeConfirming={stocktakeConfirming}
        />
      </CustomModal>
      {/* stock take create modal */}
      <CustomModal
        show={showCreateStocktakeModal}
        handleClose={handleCloseCreateStocktakeModal}
        title=""
        backdrop="static"
      >
        <CreateStocktakeModal
          // handleShowCreateStocktakeModal={handleShowCreateStocktakeModal}
          locationDropdown={locationDropdown}
          brandsDropdown={brandsDropdown}
          gendersDropdown={gendersDropdown}
          handleCreateStocktake={handleCreateStocktake}
          newStocktake={newStocktake}
          setNewStocktake={setNewStocktake}
        />
      </CustomModal>
      {/* stock take line modal */}
      <CustomModal
        show={showStocktakeLinesModal}
        handleClose={handleCloseStocktakeLinesModal}
        title=""
        backdrop="static"
      >
        <StocktakeLineModal
          handleShowStocktakeLinesModal={handleShowStocktakeLinesModal}
          data={selectedStocktake}
          stocktakeStatus={selectedStocktakeStatus}
          stocktakeLocationId={selectedStocktakeLocationId}
          selectAll={selectAll}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          handleShowApproveAdjustmentsModal={handleShowApproveAdjustmentsModal}
          handleConfirmStocktake={handleConfirmStocktake}
          handleAdjustTotal={handleAdjustTotal}
          handleSaveVariances={handleSaveVariances}
          handleInTransitClick={handleInTransitClick}
        />
      </CustomModal>

      <Container fluid>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Body>
                  <Row>
                    <Col md="">
                      <DateRange
                        title="Date"
                        startDate={dateRange[0]}
                        endDate={dateRange[1]}
                        range={true}
                        handleChange={handleDatesChange}
                      />
                    </Col>
                    <Col md="">
                      <CustomSelect
                        title="Location"
                        placeholder="Location"
                        id={locationId}
                        data={locationDropdown}
                        handleChange={(e) => handleSelectLocation(e.value)}
                        withAll={true}
                      />
                    </Col>
                    <Col md="">
                      <CustomSelect
                        title="Brands"
                        placeholder="Brand"
                        id={brandId}
                        data={brandsDropdown}
                        handleChange={(e) => handleSelectBrand(e.value)}
                        withAll={true}
                      />
                    </Col>
                    <Col md="">
                      <CustomSelect
                        title="Status"
                        placeholder="Status"
                        id={statusId}
                        data={statusDropdown}
                        handleChange={(e) => setStatusId(e.value)}
                        withAll={true}
                      />
                    </Col>
                    <Col md="">
                      <CustomSelect
                        title="Gender"
                        placeholder="Gender"
                        id={genderId}
                        data={gendersDropdown}
                        handleChange={(e) => setGenderId(e.value)}
                        withAll={true}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <StocktakeTable
                data={stocktakeData}
                locations={locationDropdown}
                users={users}
                handleShowCreateStocktakeModal={handleShowCreateStocktakeModal}
                getStocktakeLine={getStocktakeLines}
                handleDeleteStocktake={handleDeleteStocktake}
                handleShowStocktakeAnalysisModal={
                  handleShowStocktakeAnalysisModal
                }
              />
            </Col>
          </Row>
          {/* <StocktakeLineTable
            data={data}
            getStocktakeLine={getStocktakeLines}
            deleteStocktake={deleteStocktake}
            handleShowCreateStocktakeModal={handleShowCreateStocktakeModal}
          /> */}
        </Container>
      </Container>
    </>
  );
};

export default Stocktake;
