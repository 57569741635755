import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Form,
    Row,
    Col,
    Card, Table
} from "react-bootstrap";
import CustomSelect from "components/Shared/CustomSelect";
import CurrencyInput from 'react-currency-input-field';
function BuyersCollectionProductDetailModal(props) {
    const fileInputRef = useRef(null);

    const [localValue, setLocalValue] = useState(props.buyersCollectionProduct.colour || '');

    const handleInputChange = (e) => {
        const upperCaseValue = e.target.value.toUpperCase();
        setLocalValue(upperCaseValue);
        props.handleDetailChange("colour", upperCaseValue);
    }

    const isTablet = /iPad|Android/.test(navigator.userAgent);

    useEffect(() => {
        if (isTablet) {
            document.documentElement.style.setProperty('--modal-height-tablet', '100vh');
            document.documentElement.style.setProperty('--modal-margin-tablet', '0');
            document.documentElement.style.setProperty('--modal-padding-top', '0');
        } else {
            document.documentElement.style.setProperty('--modal-width', '1100px');
        }

        // Check if props.openWithInput is true and the fileInputRef is available, then trigger the click event.
        if (props.openWithInput && fileInputRef.current) {
            fileInputRef.current.click();
            props.handleAddProductDetail(false)
        }
    }, [])

    const handleFocus = (event) => {
        event.target.select();
    };

    var productVarient = props.buyersCollectionProduct.productVariants;
    return (
        <>
            <Form action="" className="form" method="">
                <Card>
                    <Card.Body>
                        <Row>
                            {props.buyersCollectionProduct?.images?.length > 0 &&
                                props.buyersCollectionProduct.images.map((image, index) => (
                                    <Col key={index} className="pr-1" md="4">
                                        <div className="logoImage">
                                            <button className="cursor-pointer btn-unstyled"
                                                onClick={(e) => props.handleClickImage(index, e)}>
                                                <img
                                                    style={{ height: "204px" }}
                                                    alt="..."
                                                    width={170}
                                                    className="border-gray"
                                                    src={`data:image/jpeg;base64,${image}`}
                                                />
                                                <input
                                                    id="ag-upload"
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    onChange={(e) => props.handleChangeImage(e)}
                                                    hidden
                                                />
                                            </button>
                                            <i className="fas fa-times"
                                                onClick={() => props.handleDeleteImage(index)}
                                                style={{
                                                    top: "5px",
                                                    right: "5px",
                                                    fontSize: "1.2rem",
                                                    cursor: "pointer",
                                                    color: "red",
                                                }}
                                            />
                                        </div>
                                    </Col>
                                ))}
                            {(props.buyersCollectionProduct?.images == null || props.buyersCollectionProduct?.images?.length < 3) &&
                                <Col className="pr-1" md="4">
                                    <Card className="addIcon">
                                        <Card.Body className="d-flex align-items-center justify-content-center">

                                            <div className="logoImage">
                                                <Button
                                                    className="btn-link btn-xs pull-right text-success"
                                                    variant="danger"
                                                    onClick={(e) => props.handleClickImage(-1, e)}
                                                >
                                                    <i className="fas fa-plus" style={{ fontSize: "2rem" }}></i>
                                                    <input
                                                        id="ag-upload"
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        onChange={(e) => props.handleChangeImage(e)}
                                                        hidden
                                                        ref={fileInputRef}
                                                    />
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>}
                        </Row>
                        <Row>
                            <Col className="pr-1" md="6">
                                <Form.Group>
                                    <label>Name</label>
                                    <Form.Control
                                        onChange={(e) => props.handleDetailChange("name", e.target.value)}
                                        defaultValue={props.buyersCollectionProduct.name}
                                        placeholder="Name"
                                        type="text"
                                        onBlur={(e) => props.validateInput("name", e.target.value)}
                                    />
                                    {props.error.name && <label style={{ color: "red" }} className='err'>{props.error.name}</label>}
                                </Form.Group>
                            </Col>
                            <Col className="pr-1" md="6">
                                <Form.Group>
                                    <label>Brand Code</label>
                                    <Form.Control
                                        onChange={(e) => props.handleDetailChange("brandCode", e.target.value)}
                                        defaultValue={props.buyersCollectionProduct.brandCode}
                                        placeholder="Brand Code"
                                        type="text"
                                    />
                                </Form.Group>
                            </Col>

                            <Col className="pr-1" md="6">
                                <Form.Group>
                                    <label>Colour</label>
                                    <Form.Control
                                        onChange={handleInputChange}
                                        value={localValue}
                                        placeholder="Color"
                                        type="text"
                                    />
                                </Form.Group>
                            </Col>

                            <Col className="pr-1" md="6">
                                <Form.Group>
                                    <label>Category</label>
                                    <CustomSelect
                                        value={props.buyersCollectionProduct.productCategoryId}
                                        handleChange={(e) => props.handleDetailChange("productCategoryId", e.value)}
                                        placeholder="Category"
                                        data={props.productCategory}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Card className="strpied-tabled-with-hover" style={{ marginTop: "15px" }}>
                    <Row>
                        <Col md="2">
                            <Card.Title as="h4">Item Detail</Card.Title>
                        </Col>
                        <Col md="9">
                            <Row>
                                <Col md="2" className="pt-1">
                                    <input
                                        type="checkbox"
                                        checked={props.isSameDetailsChecked}
                                        onChange={props.handleSameDetailsCheckboxChange}
                                    />
                                    <label>Same details</label>
                                </Col>

                                {props.isSameDetailsChecked && (
                                    <>
                                        <Col md="10" >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <label className="pt-2">Cost:</label>
                                                <Form.Group style={{ paddingLeft: "10px", paddingRight: "20px" }}>
                                                    <CurrencyInput
                                                        className="form-control"
                                                        name="cost"
                                                        placeholder="Cost"
                                                        value={productVarient[0].cost}
                                                        decimalsLimit={2}
                                                        onValueChange={(e) => props.handleSameDetailsChange(e, "cost")}
                                                        prefix="&pound;"
                                                        style={{ width: "65px" }}
                                                    />
                                                </Form.Group>
                                                <label className="pt-2">RRP:</label>
                                                <Form.Group style={{ paddingLeft: "10px", paddingRight: "20px" }}>
                                                    <CurrencyInput
                                                        className="form-control"
                                                        name="rrp"
                                                        placeholder="RRP"
                                                        value={productVarient[0].rrp}
                                                        decimalsLimit={2}
                                                        onValueChange={(e) => props.handleSameDetailsChange(e, "rrp")}
                                                        prefix="&pound;"
                                                        style={{ width: "65px" }}
                                                    />
                                                </Form.Group>
                                                <label className="pt-2">VAT:</label>
                                                <Form.Group style={{ paddingLeft: "10px", paddingRight: "20px" }}>
                                                    <CustomSelect
                                                        value={productVarient[0].vatRateId}
                                                        name="vatRateId"
                                                        handleChange={(e) => props.handleSameDetailsDropdownChange(e.value)}
                                                        onFocus={handleFocus}
                                                        data={props.vat}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Col>

                        <Col md="1">
                            <Button onClick={props.handleBuyersCollectionSizeModal}>
                                Sizes
                            </Button>
                        </Col>
                    </Row>
                    <Card.Body className="table-responsive p-0">

                        {
                            (props.buyersCollectionProduct?.productVariants?.length !== 0) &&
                            < Col md="20" >
                                <Table className="table-hover table-striped w-full">
                                    <thead>
                                        <tr>
                                            <th>Size</th>
                                            <th>Cost</th>
                                            <th>RRP</th>
                                            <th>VAT</th>
                                            <th>QTY</th>
                                            <th>war</th>
                                            {productVarient != undefined && productVarient[0].allocations.length > 0 && productVarient[0].allocations.map((allocation, index) => {
                                                return <th key={index} onClick={() => props.handleLocationAllocationChange(index)}>{allocation.locationName}</th>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productVarient != undefined && productVarient.map((product, index) => {
                                            return (
                                                <tr key={index}>

                                                    <td>
                                                        {product.sizeName}
                                                    </td>
                                                    <td>
                                                        <Form.Group>
                                                            <CurrencyInput
                                                                className="form-control"
                                                                name="cost"
                                                                placeholder="Cost"
                                                                value={product.cost}
                                                                decimalsLimit={2}
                                                                onValueChange={(e) => props.handleProductVariantsCurrencyChange(e, index, "cost")}                                                   
                                                                prefix="&pound;"
                                                                disabled={props.isSameDetailsChecked}
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group>
                                                            <CurrencyInput
                                                                className="form-control"
                                                                name="rrp"
                                                                placeholder="RRP"
                                                                value={product.rrp}
                                                                decimalsLimit={2}
                                                                onValueChange={(e) => props.handleProductVariantsCurrencyChange(e, index, "rrp")}
                                                                prefix="&pound;"
                                                                disabled={props.isSameDetailsChecked}
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                            <CustomSelect
                                                                value={product.vatRateId}
                                                                handleChange={(e) => props.handleProductVariantsDropdownChange(e, index)}
                                                                onFocus={handleFocus}
                                                                data={props.vat}
                                                                isDisabled={props.isSameDetailsChecked}
                                                            />
                                                    </td>

                                                    <td>
                                                        <Form.Group>
                                                            <Form.Control
                                                                onChange={(e) => props.handleProductVariantsChange(e, index)}
                                                                onFocus={handleFocus}
                                                                value={product.quantity}
                                                                placeholder="0"
                                                                type="number"
                                                                min={product.allocations.reduce((acc, allocation) => acc + allocation.quantity, 0)}
                                                                name="quantity"
                                                                style={{ width: "35px", padding: "3px 1px 3px 3px" }}
                                                            />
                                                        </Form.Group>
                                                    </td>

                                                    <td>
                                                        <Form.Group>
                                                            <Form.Control
                                                                value={product.quantity - product.allocations.reduce((sum, item) => sum + item.quantity, 0)}
                                                                type="text"
                                                                name="war"
                                                                style={{
                                                                    width: "35px",
                                                                    padding: "3px",
                                                                    backgroundColor: (product.quantity - product.allocations.reduce((sum, item) => sum + item.quantity, 0)) > 0 ? "orange" : "inherit",
                                                                }}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </td>

                                                    {product.allocations.length > 0 && product.allocations.map((allocation, allocationIndex) => {
                                                        return (
                                                            <td key={allocationIndex}>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        onChange={(e) => props.handleAllocationChange(index, e, allocationIndex)}
                                                                        onFocus={handleFocus}
                                                                        value={allocation.quantity}
                                                                        placeholder={allocation.locationName}
                                                                        type="number"
                                                                        min="0"
                                                                        name={allocation.locationName}
                                                                        style={{
                                                                            width: "35px",
                                                                            padding: "3px 1px 3px 3px",
                                                                            backgroundColor: allocation.quantity > 0 ? "lightgreen" : "inherit"
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        }
                        {
                            (props.buyersCollectionProduct?.productVariants?.length == 0) &&
                            <>You have no variants selected. Please click sizes to continue</>
                        }
                    </Card.Body>
                </Card>
                {
                    props.buyersCollectionProduct?.id !== 0 ?
                        <>
                            {props.buyersCollectionProductSaving ?
                                <Button
                                    className="btn-fill float-end"
                                    variant="primary"
                                >
                                    Update
                                </Button>
                                :
                                <Button
                                    className="btn-fill float-end"
                                    variant="primary"
                                    onClick={props.handleUpdateProductDetail}
                                >
                                    Update
                                </Button>
                            }
                        </>
                        :
                        <>
                            {props.buyersCollectionProductSaving ?
                                <Button
                                    className="btn-fill float-end"
                                    variant="primary"
                                >
                                    Save
                                </Button>
                                :
                                <Button
                                    className="btn-fill float-end"
                                    variant="primary"
                                    onClick={props.handleSaveProductDetail}
                                >
                                    Save
                                </Button>
                            }
                        </>
                }
                <Button
                    className="btn-fill float-end"
                    variant="info"
                    onClick={props.handleCloseProductDetailModal}
                >
                    Cancel
                </Button>
            </Form>
        </>
    )
}
export default BuyersCollectionProductDetailModal;