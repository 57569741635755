import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showSweetAlert } from "components/Shared/CustomAlert";
// react-bootstrap components
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container,
} from "react-bootstrap";

import {
    ADMIN_USERMANAGEMENT_EDIT,
    ADMIN_USERMANAGEMENT_DELETE,
} from "../../constants/Permissions.js";
import { getPermission } from "../../utils/Permissions.js";

function UserTable(props) {
    const account = useSelector((state) => state.user);

    const handlePermission = () => {
        showSweetAlert("unauthorized");
    };

    const renderTableData = (userEdit, userDelete) => {
        return props.data.map((user, index) => {
            const {
                id,
                username,
                firstName,
                lastName,
                phoneNumber,
                email,
                roleId,
                locationId,
                roleName,
                locationName,
            } = user;
            return (
                <tr key={`UserManagement${index}${id}`}>
                    <td className="text-center">{id}</td>
                    <td onClick={() => props.handleShow(id)}>
                        <a href="#id">{username}</a>
                    </td>
                    <td>{firstName}</td>
                    <td>{lastName}</td>
                    <td>{email}</td>
                    <td>{phoneNumber}</td>
                    <td>{roleName}</td>
                    {/* <td>TBC</td> */}
                    <td>{locationName}</td>
                    <td className="td-actions text-right">
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={<Tooltip id="tooltip-255158527">Remove..</Tooltip>}
                        >
                            {userDelete ? (
                                <Button
                                    className="btn-link btn-xs btn-action"
                                    variant="danger"
                                    onClick={() => props.handleDelete(id)}
                                >
                                    <i className="fas fa-times"></i>
                                </Button>
                            ) : (
                                <Button
                                    className="btn-link btn-xs btn-action"
                                    variant="default"
                                    onClick={() => handlePermission()}
                                >
                                    <i className="fas fa-times"></i>
                                </Button>
                            )}
                        </OverlayTrigger>
                    </td>
                </tr>
            );
        });
    };

    return (
        <>
            {alert}
            <Card className="table-with-links elysium-h100">
                <Card.Header>
                    <Card.Title
                        as="h4"
                        className="d-flex justify-content-between align-items-center"
                    >
                        User Management
                        {getPermission(account.permissions, ADMIN_USERMANAGEMENT_EDIT) ? (
                            <Button
                                className="btn-fill"
                                variant="primary"
                                onClick={() => props.handleAdd()}
                            >
                                Add
                            </Button>
                        ) : (
                            <Button
                                className="btn-fill"
                                variant="primary"
                                onClick={() => props.handlePermission()}
                            >
                                Add
                            </Button>
                        )}
                    </Card.Title>
                    <p className="card-category"></p>
                </Card.Header>
                <Card.Body className="table-full-width elysium-h90">
                    <Col className="overflow-auto elysium-h100">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="text-center">#</th>
                                    <th>Username</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Role Name</th>
                                    <th>Location Name</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableData(
                                    getPermission(account.permissions, ADMIN_USERMANAGEMENT_EDIT),
                                    getPermission(
                                        account.permissions,
                                        ADMIN_USERMANAGEMENT_DELETE
                                    )
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Card.Body>
            </Card>
        </>
    );
}

export default UserTable;
