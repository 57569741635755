import React from "react";
import Swal from "sweetalert2";

export const showSweetAlert = (type, name, confirmCallback) => {
  switch (type) {
    case "deleteWarning":
      Swal.fire({
        title: `Are you sure you want to delete ${name}?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: `No`,
        denyButtonColor: "#ACACAC ",
        confirmButtonColor: "#FF680B",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          if (confirmCallback) {
            confirmCallback();
          }
        }
      });
      break;
    case "deleteSuccess":
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${name} deleted successfully`,
        showConfirmButton: false,
        timer: 1500,
      });
      break;
    case "saveSuccess":
      Swal.fire({
        position: "center",
        icon: "success",
        title: `Saved successfully`,
        showConfirmButton: false,
        timer: 1500,
      });
      break;
    case "success":
      Swal.fire({
        position: "center",
        icon: "success",
        title: `Success!`,
        showConfirmButton: false,
        timer: 1500,
        html: name,
      });
      break;
    case "saveSuccessCustomMessage":
      Swal.fire({
        position: "center",
        icon: "success",
        title: `Saved successfully`,
        showConfirmButton: false,
        timer: 1500,
        html: name,
      });
      break;
    case "serverError":
      Swal.fire({
        position: "center",
        icon: "error",
        title: `Internal Server Error`,
        confirmButtonColor: "#ACACAC",
        confirmButtonText: "OK",
      });
      break;
    case "errorCustomMessage":
      Swal.fire({
        position: "center",
        icon: "error",
        title: name,
        confirmButtonColor: "#ACACAC",
        confirmButtonText: "OK",
      });
      break;
    case "unauthorized":
      Swal.fire({
        position: "center",
        icon: "warning",
        title: `You are not authorized to use this functionality.`,
        confirmButtonColor: "#ACACAC",
        confirmButtonText: "OK",
      });
      break;
    case "systemCategory":
      Swal.fire({
        position: "center",
        icon: "warning",
        title: `You cannot edit or delete system categories.`,
        confirmButtonColor: "#ACACAC",
        confirmButtonText: "OK",
      });
      break;
    case "warning":
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Are you sure?",
        text: name,
        confirmButtonColor: "#FF680B",
        confirmButtonText: "Yes",
        showDenyButton: true,
        denyButtonColor: "#ACACAC",
        denyButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          if (confirmCallback) {
            confirmCallback();
          }
        }
      });
      break;
    case "warningCustomMessage":
      Swal.fire({
        position: "center",
        icon: "warning",
        text: name,
        confirmButtonColor: "#FF680B",
        confirmButtonText: "Yes",
        showDenyButton: true,
        denyButtonColor: "#ACACAC",
        denyButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          if (confirmCallback) {
            confirmCallback();
          }
        }
      });
      break;
    case "warningOkOnly":
      Swal.fire({
        position: "center",
        icon: "warning",
        html: name,
        confirmButtonColor: "#ACACAC",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          if (confirmCallback) {
            confirmCallback();
          }
        }
      });
      break;
    default:
      break;
  }
};

//{props.type == "deleteWarning" &&

//    <SweetAlert
//        warning
//        style={{ display: "block", marginTop: "-100px" }}
//        title="Are you sure?"
//        onConfirm={() => props.handleConfirm()}
//        onCancel={() => props.handleCancel()}
//        confirmBtnBsStyle="info"
//        cancelBtnBsStyle="danger"
//        confirmBtnText="Yes, delete it!"
//        cancelBtnText="Cancel"
//        showCancel
//    >
//        Are you sure you want to archive this {props.name}?
//    </SweetAlert>
//}
//{props.type == "deleteSuccess" &&
//    <SweetAlert
//        success
//        style={{ display: "block", marginTop: "-100px" }}
//        title="Success"
//        onConfirm={() => props.handleConfirm()}
//        onCancel={() => props.handleCancel()}
//        confirmBtnBsStyle="info"
//    >
//        {props.name} deleted successfully.
//    </SweetAlert>
//}
//{props.type == "saveSuccess" &&
//    <SweetAlert
//        success
//        style={{ display: "block", marginTop: "-100px" }}
//        title="Success"
//        onConfirm={() => props.handleConfirm()}
//        onCancel={() => props.handleCancel()}
//        confirmBtnBsStyle="info"
//    >
//        {props.name} saved successfully.

//    </SweetAlert>
//}
//{props.type == "saveSuccessCustomMessage" &&
//    <SweetAlert
//        success
//        style={{ display: "block", marginTop: "-100px" }}
//        title="Success"
//        onConfirm={() => props.handleConfirm()}
//        onCancel={() => props.handleCancel()}
//        confirmBtnBsStyle="info"
//    >
//        <span dangerouslySetInnerHTML={{ __html: props.name }} />
//    </SweetAlert>
//}
//{props.type == "serverError" &&
//    <SweetAlert
//        danger
//        style={{ display: "block", marginTop: "-100px" }}
//        title="Error"
//        onConfirm={() => props.handleConfirm()}
//        onCancel={() => props.handleCancel()}
//        confirmBtnBsStyle="info"
//    >
//        Internal Server Error.
//    </SweetAlert>
//}
//{props.type == "unauthorized" &&
//    <SweetAlert
//        danger
//        style={{ display: "block", marginTop: "-100px" }}
//        title="Warning"
//        onConfirm={() => props.handleConfirm()}
//        onCancel={() => props.handleCancel()}
//        confirmBtnBsStyle="info"
//    >
//        You are not authorized to use this functionality.
//    </SweetAlert>
//}
//{props.type == "systemCategory" &&
//    <SweetAlert
//        danger
//        style={{ display: "block", marginTop: "-100px" }}
//        title="Warning"
//        onConfirm={() => props.handleConfirm()}
//        onCancel={() => props.handleCancel()}
//        confirmBtnBsStyle="info"
//    >
//        You cannot edit or delete system categories.
//    </SweetAlert>
//}
//{props.type == "warning" &&
//    <SweetAlert
//        warning
//        style={{ display: "block", marginTop: "-100px" }}
//        title="Are you sure?"
//        onConfirm={() => props.handleConfirm()}
//        onCancel={() => props.handleCancel()}
//        confirmBtnBsStyle="info"
//        cancelBtnBsStyle="danger"
//        confirmBtnText="Yes!"
//        cancelBtnText="No"
//        showCancel
//    >
//        {props.message}
//    </SweetAlert>
//}
//{props.type == "warningCustomMessage" &&
//    <SweetAlert
//        warning
//        style={{ display: "block", marginTop: "-100px" }}
//        title={props.title}
//        onConfirm={() => props.handleConfirm()}
//        onCancel={() => props.handleCancel()}
//        confirmBtnBsStyle="info"
//        cancelBtnBsStyle="danger"
//        confirmBtnText="Yes!"
//        cancelBtnText="No"
//        showCancel
//    >
//        {props.message}
//    </SweetAlert>
//}
//{props.type == "warningOkOnly" &&
//    <SweetAlert
//        warning
//        style={{ display: "block", marginTop: "-100px" }}
//        title={props.title}
//        onConfirm={() => props.handleConfirm()}
//        confirmBtnBsStyle="info"
//        confirmBtnText="Ok"
//    >
//        <span dangerouslySetInnerHTML={{ __html: props.message }} />
//    </SweetAlert>
//}
