import React, { useEffect } from "react";

import CustomSelect from "components/Shared/CustomSelect";
import DateRange from "components/Shared/DateRange";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
  Form,
} from "react-bootstrap";
function AddEditPurchaseOrderModal(props) {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "500px");
  }, []);

  return (
    <>
      <Container fluid className="PurchaseModal">
        <Form action="" method="">
          <Row>
            <Col md="6" className="pt-3" style={{ paddingLeft: "6px" }}>
              <Form.Group>
                <h5 className="title">Purchase Order Number</h5>
                <Form.Control
                  placeholder="Purchase Order Number"
                  type="text"
                  value={props.selectedPurchaseOrder?.purchaseOrderNumber}
                  onChange={(e) =>
                    props.handleChangePurchaseOrder(
                      "purchaseOrderNumber",
                      e.target.value,
                    )
                  }
                  onBlur={(e) =>
                    props.validateInput("purchaseOrderNumber", e.target.value)
                  }
                ></Form.Control>
                {props.validation.purchaseOrderNumber && (
                  <label style={{ color: "red" }} className="err">
                    {props.validation.purchaseOrderNumber}
                  </label>
                )}
              </Form.Group>
            </Col>
            <Col md="6" className="pt-3">
              <Form.Group>
                <CustomSelect
                  title="Brand"
                  placeholder="Brand"
                  id={props.brands.id}
                  data={props.brands}
                  value={props.selectedPurchaseOrder?.brandId}
                  handleChange={(e) =>
                    props.handleDropDownChange(
                      e.label,
                      e.value,
                      "brandId",
                      "brandName",
                    )
                  }
                  withAll={true}
                />
                {props.validation.brandId && (
                  <label style={{ color: "red" }} className="err">
                    {props.validation.brandId}
                  </label>
                )}
              </Form.Group>
            </Col>
            <Col md="6" className="pt-3">
              <Form.Group>
                <CustomSelect
                  title="Season"
                  placeholder="Season"
                  id={props.season.id}
                  data={props.season}
                  value={props.selectedPurchaseOrder?.seasonId}
                  handleChange={(e) =>
                    props.handleDropDownChange(
                      e.label,
                      e.value,
                      "seasonId",
                      "season",
                    )
                  }
                />
                {props.validation.seasonId && (
                  <label style={{ color: "red" }} className="err">
                    {props.validation.seasonId}
                  </label>
                )}
              </Form.Group>
            </Col>
            <Col md="6" className="pt-3">
              <Form.Group>
                <DateRange
                  title="Estimated Delivery Date"
                  startDate={props.selectedPurchaseOrder.estimatedDeliveryDate}
                  handleChange={props.handleDateChange}
                  range={false}
                />
                {props.validation.estimatedDeliveryDate && (
                  <label style={{ color: "red" }} className="err">
                    {props.validation.estimatedDeliveryDate}
                  </label>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="pt-3">
              <Form.Group>
                <CustomSelect
                  title="Location"
                  placeholder="Location"
                  // id={props.location.id}
                  data={props.locationData}
                  value={props.selectedPurchaseOrder?.locationId}
                  handleChange={(e) =>
                    props.handleDropDownChange(
                      "location",
                      e.value,
                      "locationId",
                      "location",
                    )
                  }
                />
                {props.validation.locationId && (
                  <label style={{ color: "red" }} className="err">
                    {props.validation.locationId}
                  </label>
                )}
              </Form.Group>
            </Col>
          </Row>
          {props.selectedPurchaseOrder?.id == 0 ? (
            <Button
              className="btn-fill float-end"
              variant="primary"
              onClick={() => props.handleAddPurchaseOrder()}
            >
              Save
            </Button>
          ) : (
            <Button
              className="btn-fill float-end"
              variant="primary"
              onClick={() => props.handleUpdatePurchaseOrder()}
            >
              Update
            </Button>
          )}
          <Button
            className="btn-fill float-end"
            variant="info"
            onClick={props.handlePurchaseOrderCancel}
          >
            Cancel
          </Button>
        </Form>
      </Container>
    </>
  );
}
export default AddEditPurchaseOrderModal;
