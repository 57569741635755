import React, { useEffect } from "react";
import CustomTable from "components/Shared/CustomTable";
import PurchaseOrderLineTable from "./PurchaseOrderLineTable";
import { formatCurrency } from "utils/FormatCurrency.js";
import moment from "moment";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
  Form,
} from "react-bootstrap";

import { PURCHASEORDER_EDIT } from "constants/Permissions.js";
import { getPermission } from "utils/Permissions.js";
import { useSelector } from "react-redux";

function PurchaseOrderModal(props) {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "1800px");
  }, []);

  return (
    <>
      {alert}
      <Container fluid className="PurchaseModal">
        <Col md="12">
          <Card>
            <Card.Body>
              {getPermission(user.permissions, PURCHASEORDER_EDIT) && (
                <Row>
                  <Button
                    className="btn-link btn-xs position-absolute top-0 end-0"
                    variant="success"
                    onClick={() => props.handleEditModelPurchaseOrder()}
                    style={{ width: "30px", top: "5px", right: "5px" }} // Set the width and position
                  >
                    <i className="fas fa-edit"></i>
                  </Button>
                </Row>
              )}
              <Row>
                <Col md="3">
                  <Form.Group>
                    <label>Purchase Order Number</label>
                    <Form.Control
                      placeholder="Purchase Order Number"
                      type="text"
                      value={props.selectedPurchaseOrder.purchaseOrderNumber}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Season</label>
                    <Form.Control
                      placeholder="Season"
                      type="text"
                      value={props.selectedPurchaseOrder.season}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Estimated Delivery Date</label>
                    <Form.Control
                      placeholder="Estimated Delivery Date"
                      type="text"
                      value={moment(
                        props.selectedPurchaseOrder.estimatedDeliveryDate
                      ).format("DD/MM/YYYY")}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Brand Name</label>
                    <Form.Control
                      placeholder="Brand Name"
                      type="text"
                      value={props.selectedPurchaseOrder.brandName}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>User Full Name</label>
                    <Form.Control
                      placeholder="User Full Name"
                      type="text"
                      value={props.selectedPurchaseOrder.userFullName}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Cost Price</label>
                    <Form.Control
                      placeholder="Cost Price"
                      value={formatCurrency(
                        props.selectedPurchaseOrder.costPrice
                      )}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Tax</label>
                    <Form.Control
                      placeholder="Tax"
                      value={formatCurrency(props.selectedPurchaseOrder.tax)}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Discount</label>
                    <Form.Control
                      placeholder="Discount"
                      value={formatCurrency(
                        props.selectedPurchaseOrder.discount
                      )}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Total</label>
                    <Form.Control
                      placeholder="Total"
                      value={formatCurrency(props.selectedPurchaseOrder.total)}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group>
                    <label>Location</label>
                    <Form.Control
                      placeholder="Location"
                      value={props.selectedPurchaseOrder.locationName}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Container>

      <Container fluid className="PurchaseModalTable">
        <Row>
          <Col md="12">
            <PurchaseOrderLineTable
              PurchaseOrderLineData={props.PurchaseOrderLineData}
              isLodaing={props.isLodaing}
              handleSearchChange={props.handleSearchChange}
              search={props.search}
              handleAddPurchaseOrderLine={props.handleAddPurchaseOrderLine}
              handleEditPurchaseOrderLine={props.handleEditPurchaseOrderLine}
              handleDeletePurchaseOrderLine={props.handleDeletePurchaseOrderLine}
              handleGetItemCard={props.handleGetItemCard}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default PurchaseOrderModal;
