import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Card,
  Container,
  Spinner,
} from "react-bootstrap";
import CustomSelect from "components/Shared/CustomSelect";

import SpecialOrderProductsTable from "./SpecialOrderProductsTable";

const RequestSpecialOrder = (props) => {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "1200px");
    document.documentElement.style.setProperty("--modal-width-tablet", "50%");
  }, []);

  const [showEnterNewProduct, setShowEnterNewProduct] = useState(false);
  const handleToggleEnterNewProduct = () => {
    setShowEnterNewProduct(!showEnterNewProduct);
    props.setNewSpecialOrder(props.cleanSpecialOrder);
    props.setSelectedProduct(null);
  };

  return (
    <>
      <Form action="" className="form" method="">
        <Card>
          <Card.Header>Selected Product</Card.Header>
          <Card.Body>
            {!showEnterNewProduct ? (
              <>
                <Row>
                  <Col>
                    <Row>
                      <Form.Group>
                        <label>Product Name</label>
                        <Form.Control
                          value={props.selectedProduct?.name}
                          className=""
                          placeholder="Selected Product Name"
                          type="text"
                          // as="textarea"
                          id="product-name"
                          onChange={(e) => {
                            props.setNewSpecialOrder({
                              ...props.newSpecialOrder,
                              productName: e.target.value,
                            });
                          }}
                          disabled
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group className="">
                          <label>Brand Code</label>
                          <Form.Control
                            value={props.selectedProduct?.brandCode}
                            placeholder="Selected Product Brand Code"
                            type="text"
                            onChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                brandCode: e.target.value,
                              });
                            }}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <label>Colour</label>
                          <Form.Control
                            value={props.selectedProduct?.colour}
                            placeholder="Selected Product Colour"
                            type="text"
                            onChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                colour: e.target.value,
                              });
                            }}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <label>Size</label>
                          <Form.Control
                            value={props.selectedProduct?.size}
                            className=""
                            placeholder="Selected Product Size"
                            onChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                size: e.target.value,
                              });
                            }}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="h-100 d-flex mb-2">
                      <a href="#id" onClick={handleToggleEnterNewProduct}>
                        Enter product details instead
                      </a>
                    </div>
                  </Col>
                  <Col>
                    <Row>
                      <Col className="pr-1" md="">
                        <Form.Group>
                          <label>SKU</label>
                          <Form.Control
                            value={props.selectedProduct?.sku}
                            placeholder="Selected Product Id"
                            type="text"
                            onChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                productId: e.target.value,
                              });
                            }}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col className="pr-1">
                        <Form.Group>
                          <label>Product Quantity</label>
                          <Form.Control
                            value={props.newSpecialOrder?.quantity}
                            placeholder="Quantity"
                            type="text"
                            onChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                quantity: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>
                        <Form.Group>
                          <label>Location</label>
                          <CustomSelect
                            data={props.locations ?? []}
                            // value={props.newSpecialOrder?.locationId}
                            value={props.newSpecialOrder?.locationId}
                            handleChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                locationId: e.value,
                                locationName: e.label,
                              });
                            }}
                            withBlank={true}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Container fluid className="SpecialOrderProductsTable">
                  <Row>
                    <Col md="12">
                      <SpecialOrderProductsTable
                        data={props.data}
                        setData={props.setData}
                        setSelectedProduct={props.setSelectedProduct}
                        handleSearchChange={props.handleSearchChange}
                        handlePageChange={props.handlePageChange}
                        updateData={props.updateData}
                        search={props.search}
                        hasMore={props.hasMore}
                        isLoading={props.isLoading}
                        take={props.take}
                      />
                    </Col>
                  </Row>
                </Container>
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <Form.Group className="h-75 mb-5">
                      <label>Product Description</label>
                      <Form.Control
                        value={props.newSpecialOrder?.description}
                        className="h-100"
                        as="textarea"
                        placeholder="Product Description"
                        onChange={(e) => {
                          props.setNewSpecialOrder({
                            ...props.newSpecialOrder,
                            productDescription: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <a href="#id" onClick={handleToggleEnterNewProduct}>
                      Select existing product
                    </a>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Product Name</label>
                          <Form.Control
                            value={props.newSpecialOrder?.productName}
                            placeholder="Product Name"
                            type="text"
                            onChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                productName: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <label>Product Link</label>
                          <Form.Control
                            value={props.newSpecialOrder?.productLink}
                            placeholder="Product Link"
                            type="text"
                            onChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                productLink: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Brand Code</label>
                          <Form.Control
                            value={props.newSpecialOrder?.brandCode}
                            placeholder="Brand Code"
                            type="text"
                            onChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                brandCode: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <label>Product Quantity</label>
                          <Form.Control
                            value={props.newSpecialOrder?.quantity}
                            placeholder="Quantity"
                            type="number"
                            onChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                quantity: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Colour</label>
                          <Form.Control
                            value={props.newSpecialOrder?.colour}
                            placeholder="Colour"
                            type="text"
                            onChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                colour: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <label>Size</label>
                          <Form.Control
                            value={props.newSpecialOrder?.size}
                            placeholder="Size"
                            type="text"
                            onChange={(e) => {
                              props.setNewSpecialOrder({
                                ...props.newSpecialOrder,
                                size: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Group className="mb-3">
                        <label>Locations</label>
                        <CustomSelect
                          data={props.locations ?? []}
                          value={props.newSpecialOrder?.locationId}
                          handleChange={(e) => {
                            props.setNewSpecialOrder({
                              ...props.newSpecialOrder,
                              locationId: e.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <Button
              className="btn-fill float-end"
              variant="primary"
              onClick={() =>
                props.requestSpecialOrder(
                  showEnterNewProduct === true ? false : true
                )
              }
              disabled={props.isLoadingSpecialOrder}
            >
              {props.isLoadingSpecialOrder ? (
                <div style={{ height: "1rem", width: "2rem" }}>
                  <Spinner
                    animation="border"
                    style={{ height: "0.8rem", width: "0.8rem" }}
                  />
                </div>
              ) : (
                "Request"
              )}
            </Button>
            <Button
              className="btn-fill float-end"
              variant="info"
              onClick={props.handleCloseSpecialOrderModal}
            >
              Cancel
            </Button>
            <div className="clearfix"></div>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default RequestSpecialOrder;
