import React, { useState, useEffect, useRef } from "react";
import {
    Card,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import CustomSelect from '../../components/Shared/CustomSelect';
import BuyersTable from "components/Buyers/BuyersTable";
import CustomModal from "components/Shared/CustomModal";
import AddEditBuyersCollection from "components/Buyers/AddEditBuyersCollection";
import { showSweetAlert } from 'components/Shared/CustomAlert';
import BuyersCollectionDetailModal from "components/Buyers/BuyersCollectionDetailModal";
import BuyersCollectionProductAddModal from "components/Buyers/BuyersCollectionProductAddModal";
import BuyersCollectionProductDetailModal from "components/Buyers/BuyersCollectionProductDetailModal";
import ApprovalCommentsInput from "components/Buyers/ApprovalCommentsInput";
import BuyersCollectionSizeModal from "components/Buyers/BuyersCollectionSizeModal";
import ItemCard from "../../components/ItemCard/ItemCard";
import InventoryModal from "../../components/ItemCard/InventoryModal";
import { useSelector } from 'react-redux';

import { BUYERS_VIEW } from 'constants/Permissions.js';
import { getPermission } from 'utils/Permissions.js';

function Buyers() {
    // ### STATE ###
    const [isLoading, setIsLoading] = useState(true);
    const [buyers, setBuyers] = useState([]);
    const [locations, setLocations] = useState([]);
    const [brand, setBrand] = useState([]);
    const [productCategory, setProductCategory] = useState([]);
    const [gender, setGender] = useState([]);
    const [season, setSeason] = useState([]);
    const [status, setStatus] = useState([]);
    const [page, setPage] = useState(0);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(20);
    const [search, setSearch] = useState("");
    const [descending, setDescending] = useState(true);
    const [orderBy, setOrderBy] = useState("DateCreated");
    const [buyersCollection, setBuyersCollection] = useState([]);
    const [buyersCollectionProductId, setBuyersCollectionProductId] = useState(0);
    const [buyersCollectionData, setBuyersCollectionData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [buyerId, setBuyerId] = useState(0);
    const [data, setData] = useState([]);
    const [buyerCollectionLocations, setBuyerCollectionLocation] = useState([]);
    const [overwrite, setOverwrite] = useState(false);
    const [categoryGroups, setCategoryGroups] = useState([]);
    const [categorySizes, setCategorySizes] = useState([]);
    const [vat, setVat] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [buyersCollectionProduct, setBuyersCollectionProduct] = useState([]);
    const [updatedSize, setUpdatedSizes] = useState([]);
    const [selectedSizeScale, setSelectedSizeScale] = useState("");
    const [sizeSelectAll, setSizeSelectAll] = useState(false);
    const [selectedImage, setSelectedImage] = useState(-1);
    const [approvalProductId, setApprovalProductId] = useState(0);
    const [approvalState, setApprovalState] = useState(false);
    const [showDetails, setShowDetails] = useState(true);

    const user = useSelector((state) => state.user);
    const [collectionLocationDetails, setCollectionLocationDetails] = useState([]);


    //Handle Modal
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    //handle BuyersCollectionDetail Modal
    const [showCollectionDetailModal, setShowCollectionDetailModal] = useState(false);
    const handleCloseCollectionDetailModal = () => setShowCollectionDetailModal(false);
    const handleShowCollectionDetailModal = () => setShowCollectionDetailModal(true);

    //handle BuyersCollectionProductAdd Modal 
    const [showBuyersCollectionProductAddModal, setShowBuyersCollectionProductAddModal] = useState(false);
    const handleCloseBuyersCollectionProductAddModal = () => setShowBuyersCollectionProductAddModal(false);
    const handleShowBuyersCollectionProductAddModal = () => setShowBuyersCollectionProductAddModal(true);

    //handle BuyersCollectionProductDetail Modal 
    const [showBuyersCollectionProductDetailModal, setBuyersCollectionProductDetailModal] = useState(false);
    const handleCloseBuyersCollectionProductDetailModal = () => setBuyersCollectionProductDetailModal(false);
    const handleShowBuyersCollectionProductDetailModal = () => setBuyersCollectionProductDetailModal(true);

    // handle size modal
    const [showSizeModal, setSizeModal] = useState(false);
    const handleCloseSizeModal = () => setSizeModal(false);
    const handleShowSizeModal = () => setSizeModal(true);

    //handle comments modal
    const [showCommentsModal, setShowCommentsModal] = useState(false);

    const [isSameDetailsChecked, setIsSameDetailsChecked] = useState(false);

    // item card properties
    const [selectedProductId, setSelectedProductId] = useState(0);
    const [selectedInventory, setSelectedInventory] = useState([]);
    const [showItemCardModal, setShowItemCardModal] = useState(false);
    const [showInventoryModal, setShowInventoryViewModal] = useState(false);
    const [itemCardSelectedTab, setItemCardSelectedTab] = useState('Detail');

    const [buyersCollectionProductSaving, setBuyersCollectionProductSaving] = useState(false);

    //on dropdown changes 
    const [locationId, setLocationId] = useState(0);
    const [brandId, setBrandId] = useState(0);
    const [seasonId, setSeasonId] = useState(0);
    const [genderId, setGenderId] = useState(0);
    const [statusId, setstatusId] = useState(0);


    const [selectedLocation, setSelectedLocation] = useState(0);

    // auto activate the input variable
    const [openWithInput, setOpenWithInput] = useState(false);

    // allocation message to appear once allocation has been successful
    const [allocationMessage, setAllocationMessage] = useState("");

    // Validation
    const [error, setError] = useState({
        name: '',
        brandId: '',
        seasonId: ''
    })

    const handleCheckboxChange = (id) => {
        setSelectedSizes((prevSelectedSizes) => {
            const index = prevSelectedSizes.indexOf(id);
            if (index !== -1) {
                const updatedList = [...prevSelectedSizes];
                updatedList.splice(index, 1);
                setUpdatedSizes((prevSizes) => ({
                    ...prevSizes,
                    sizeIds: updatedList
                }));
                return updatedList;
            } else {
                const updatedList = [...prevSelectedSizes, id];
                setUpdatedSizes((prevSizes) => ({
                    ...prevSizes,
                    ...prevSizes,
                    sizeIds: updatedList
                }));
                return updatedList;
            }
        });
    };

    const handleSelectAllChange = (event) => {
        if (event.target.checked === true) {
            setSelectedSizes(categorySizes.map(size => size.id))
            setUpdatedSizes((prevSizes) => ({
                ...prevSizes,
                sizeIds: categorySizes.map(size => size.id)
            }));
            setSizeSelectAll(true)
        } else {
            setSelectedSizes([])
            setSizeSelectAll(false)
        }
    };

    const addBuyersCollectionAllocation = async (locationId, productId) => {
        if (productId == 0) {
            return;
        }
        try {
            const response = await fetch(`/BuyersCollectionAllocation/Add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&locationId=${locationId}&productId=${productId}&overwrite=${overwrite}`, {
                method: 'post',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
            });
            const body = await response.json();
            if (body === true) {
                setAllocationMessage("Allocated to " + locations.find((loc) => loc.id === locationId).value)
                fetchBuyersCollectionProducts(selectedLocation, data.id)
            } else if (body == "Exists") {
                showSweetAlert('warning', `This product and location are already in the allocations. This will overwrite the allocations on that product and location to 1. Are you sure you want to continue?`, () => { addOverriteBuyersCollectionAllocation(locationId, productId) });
            } else {
                showSweetAlert('warningOkOnly', body, () => { addOverriteBuyersCollectionAllocation(locationId, productId) });
            }
        } catch (e) { }
    }

    const addOverriteBuyersCollectionAllocation = async (locationId, productId) => {

        try {
            const response = await fetch(`/BuyersCollectionAllocation/Add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&locationId=${locationId}&productId=${productId}&overwrite=true`, {
                method: 'post',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
            });
            const body = await response.json();
            if (body === true) {
                setAllocationMessage("Allocated to " + locations.find((loc) => loc.id === locationId).value)
                fetchBuyersCollectionProducts(selectedLocation, data.id)
            }
        } catch (e) { }
    }
    const validateInput = (name, value) => {
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "name":
                    if (value === "") {
                        stateObj[name] = "Please Enter the Name";
                    }
                    break;

                case "brandId":
                    if (value <= 0) {
                        stateObj[name] = "Please select at least one value";
                    }
                    break;
                case "seasonId":
                    if (value <= 0) {
                        stateObj[name] = "Please select at least one value";
                    }
                    break;
                default:
                    break;
            }

            return stateObj;
        });
    }

    const handleEditBuyersCollectionProductDetailModal = async (item) => {
        await fetchBuyersCollectionProduct(item.id)
        setBuyersCollectionProductId(item.id)
        handleShowBuyersCollectionProductDetailModal();

    }
    const handleBuyersCollectionSizeModal = () => {
        setUpdatedSizes({
            currentSizes: buyersCollectionProduct.productVariants,
            sizeIds: selectedSizes
        });
        setSizeSelectAll(false)
        handleShowSizeModal();
    }
    const pageRef = useRef(page);

    const handlePageChange = (newPage) => {
        pageRef.current = newPage;
        setPage(newPage);
    }

    const handleApprovalChange = async (productId, e) => {
        // Find the index of the product in the buyersCollectionData based on the productId
        const productIndex = buyersCollectionData.findIndex(
            (product) => product.id === productId
        );

        if (productIndex !== -1) {
            setShowCommentsModal(true);
            handleCloseCollectionDetailModal();
            setApprovalProductId(productId);
            setApprovalState(e);
        }
    }

    const handleCancelApprovalComments = async () => {
        setShowCommentsModal(false);
        handleShowCollectionDetailModal();
    }

    const handleCommentConfirm = async (comment) => {
        try {
            setShowCommentsModal(false);
            handleShowCollectionDetailModal();

            if (comment == '')
                comment = null

            let url = `/BuyersCollectionProduct/UpdateApproved?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&id=${approvalProductId}&approved=${approvalState}&comment=${comment}`;
            const response = await fetch((url), {
                method: 'put',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
            });

            if (response.status === 200) {
                // If the product with the given productId is found
                const updatedProducts = buyersCollectionData.map(product =>
                    product.id === approvalProductId ? { ...product, approved: approvalState } : product
                );

                setBuyersCollectionData(updatedProducts);
            } else {
                const body = await response.json();
                showSweetAlert('warningOkOnly', body);
            }
        } catch (err) {

        }
    }

    //handle Change
    const handleChange = (name, value) => {
        let Buyersvalues = {
            ...buyersCollection,
            [name]: value,
        };
        validateInput(name, value)
        setBuyersCollection(Buyersvalues)
    }

    const handleChangeImage = async (e) => {
        if (e.target.files.length > 0) {

            const files = e.target.files;
            const imagesArray = buyersCollectionProduct.images;

            const image = await readFileAsync(files[0]);

            if (selectedImage >= 0) {
                imagesArray[selectedImage] = image.substring(image.indexOf(',') + 1);
            } else {
                imagesArray.push(image.substring(image.indexOf(',') + 1));
            }

            setBuyersCollectionProduct((prevProduct) => ({
                ...prevProduct,
                images: imagesArray
            }));
        }

        setSelectedImage(-1);
    };
    const handleGetLabelPDF = async (id, sku) => {
        try {
            const url = `Product/GetProductLabelsPDF?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
            const response = await fetch(url);

            if (response.status == 200) {
                const base64String = await response.text();

                try {
                    const decodedData = Buffer.from(base64String, "base64").toString(
                        "binary"
                    );
                    const dataArray = new Uint8Array(
                        Array.from(decodedData, (char) => char.charCodeAt(0))
                    );
                    const blob = new Blob([dataArray], { type: "application/pdf" });

                    const blobUrl = window.URL.createObjectURL(blob);
                    const iframe = document.createElement("iframe");
                    iframe.style.display = "none";
                    iframe.src = blobUrl;
                    document.body.appendChild(iframe);

                    iframe.onload = function () {
                        try {
                            iframe.contentWindow.print();
                        } catch (printError) {
                            console.error("Error in printing:", printError);
                        }
                    };
                } catch (error) {
                    console.error("Error in processing the PDF:", error);
                }
            } else {
                const body = await response.text();
                showSweetAlert("warningCustomMessage", body);
            }
            setIsLoading(false);
        } catch (e) {
            showSweetAlert("serverError");
        }
    };
    const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleDeleteImage = async (id) => {
        const imagesArray = buyersCollectionProduct.images;

        imagesArray.splice(id, 1);

        setBuyersCollectionProduct((prevProduct) => ({
            ...prevProduct,
            images: imagesArray
        }));
        setSelectedImage(-1);
    };

    const handleClickImage = (index, e) => {
        e.stopPropagation();
        let image = document.getElementById('ag-upload');
        image.click();
        setSelectedImage(index);
    };

    const handleSearchChange = (value) => {
        setSearch(value)
    }


    const handleGetItemCard = (id) => {
        setSelectedProductId(id);
        setShowItemCardModal(true);
        handleCloseCollectionDetailModal();
    }

    const handleItemCardCloseModal = () => {
        setShowItemCardModal(false);
        setSelectedProductId(0);
        handleShowCollectionDetailModal();
        setItemCardSelectedTab('Detail')
    }

    const handleShowInventoryModal = (item) => {
        setSelectedInventory(item)
        setShowInventoryViewModal(true);
        setShowItemCardModal(false);
    }

    const handleHideInventoryModal = () => {
        setShowInventoryViewModal(false);
        setShowItemCardModal(true);
    }

    //fetch locations in dropdown
    const fetchLocations = async () => {
        try {
            let url = `/location/getdropdownlist?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return { id: item.id, value: item.name };

            });

            setLocations(data);
        } catch (err) {
        }
    }
    const fetchBuyersCollectionProducts = async (locationId, collectionId) => {
        // setIsLoading(true);
        try {
            let url = `BuyersCollectionProduct/GetAll?collectionId=${collectionId}&locationId=${locationId}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();
            setCollectionLocationDetails({
                allocatedStyles: body.allocatedStyles,
                units: body.units,
                cost: body.cost,
            });

            const data = body.products
            setBuyersCollectionData(data);
            setSelectedLocation(locationId)
        } catch (e) { }
        finally {
            //    setIsLoading(false);
        }
    }

    const getBuyerCollectionLocationList = async () => {
        try {
            let url = `/location/GetBuyerCollectionList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&includeArchived=false`;
            const response = await fetch(url);
            const body = await response.json();
            setBuyerCollectionLocation(body);
        } catch (e) { }
    }

    //fetch season in dropdown
    const fetchSeason = async () => {
        try {
            let url = `/CategoryValue/GetDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=4&includeArchived=false`;
            const response = await fetch(url);
            const body = await response.json();
            const seasonData = body?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setSeason(seasonData);
        } catch (err) {
        }
    }

    //fetch gender in dropdown
    const fetchGender = async () => {
        try {
            let url = `/CategoryValue/GetDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=1&includeArchived=false`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setGender(data);
        } catch (err) {
        }
    }

    //fetch brand in dropdown
    const fetchBrands = async () => {
        try {
            let url = `/CategoryValue/GetDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=2&includeArchived=false`;
            const response = await fetch(url);
            const body = await response.json();
            const brandData = body?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setBrand(brandData);
        } catch (err) {
        }
    }

    // Get product category list
    const fetchProductCategoryList = async () => {
        try {
            let url = `/CategoryValue/GetDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=3&includeArchived=false`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setProductCategory(data);
        } catch (err) {
        }
    }

    //fetch status in dropdown
    const fetchSatus = async () => {
        try {
            let url = `/BuyersCollection/GetStatusDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getIsetBuyersCollectionProducttem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setStatus(data);
        } catch (err) {
        }
    }

    //fetch buyers data
    const fetchBuyers = async () => {
        setIsLoading(true);
        try {
            let url = `/BuyersCollection/GetAllPaged?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&skip=${pageRef.current * take + skip}&take=${take}&brandId=${brandId}&seasonId=${seasonId}&genderId=${genderId}&statusId=${statusId}&search=${search}&orderBy=${orderBy}&descending=${descending}&includeArchived=false`;

            const response = await fetch(url);
            const buyers = await response.json();
            setBuyers(buyers);
            setIsLoading(false);
        } catch (err) {
        }
    }
    //fetch single buyers data
    const fetchBuyersCollectionProduct = async (id) => {
        try {
            let url = `/BuyersCollectionProduct/GetSingle?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
            const response = await fetch(url);
            const data = await response.json();
            setBuyersCollectionProduct(data)
            //   setbuyersCollectionProduct(data);
            setSelectedSizeScale(data.sizeScaleId)
            if (data.sizeScaleId != 0) {
                getAllSizesBySizeScaleId(data.sizeScaleId)
                setSelectedSizes(data.productVariants.map(variant => variant.sizeId))
            }
        } catch (err) {
        }
    }

    //fetch images as zip
    const handleDownloadImages = async (id, name) => {
        setIsLoading(true);
        try {
            const url = `BuyersCollectionProduct/GetCollectionProductImagesZip?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`
            const response = await fetch(url);
            const base64String = await response.text();
            const binaryData = Buffer.from(base64String, 'base64');
            const blob = new Blob([binaryData], { type: 'application/zip' });

            // Save file
            const Newurl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = Newurl;
            link.setAttribute('download', `${name}.zip`);
            link.click();
            setIsLoading(false)
        } catch (e) {
            showSweetAlert('serverError');
        }
    }

    //fetch location pdf
    const handleExportPDF = async (id, name) => {
        setIsLoading(true);
        try {
            const url = `BuyersCollectionProduct/GetCollectionProductImagesPDF?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`
            const response = await fetch(url);
            const base64String = await response.text();
            const binaryData = Buffer.from(base64String, 'base64');
            const blob = new Blob([binaryData], { type: "application/pdf" });

            // Save file
            const Newurl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = Newurl;
            link.setAttribute('download', `${name}.pdf`);
            link.click();
            setIsLoading(false)
        } catch (e) {
            showSweetAlert('serverError');
        }
    }

    //fetch export data
    const handleExportTemplate = async (id, name) => {
        setIsLoading(true);
        try {
            const url = `BuyersCollection/ExportBuyerCollection?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`
            const response = await fetch(url);
            const base64String = await response.text();
            const binaryData = Buffer.from(base64String, 'base64');
            const blob = new Blob([binaryData], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' });

            // Save file
            const Newurl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = Newurl;
            link.setAttribute('download', `${name}.xlsm`);
            link.click();
            setIsLoading(false)
        } catch (e) {
            showSweetAlert('serverError');
        }
    }

    //handle show Add modal when click on add button
    const handleShowAddBuyersCollection = () => {
        setBuyersCollection({
            name: "",
            brandId: 0,
            seasonId: 0
        });
        setIsEdit(false)
        handleShowModal();
    }
    // handle Handle Collection ProductDetailModal

    const handleCollectionProductDetailModal = (log) => {
        setData(log);
        setBuyersCollectionProductId(log.id);
        fetchBuyersCollectionProducts(0, log.id);
        handleShowCollectionDetailModal();
    }

    const buyersCollectionProductAddModal = () => {
        handleShowBuyersCollectionProductAddModal();
    }

    //handle add Buyers Collection
    const handleAddBuyersCollection = async () => {
        showSweetAlert('warning', `Are you sure you want to add this Buyers Collection?`, () => { handleSaveBuyersCollection() });
    }

    const handleSaveBuyersCollection = async () => {

        if (buyersCollection.name === "") {
            error.name = "Please enter a name";
        }
        if (buyersCollection.brandId <= 0) {
            error.brandId = "Please select at least one value";
        }
        if (buyersCollection.seasonId <= 0) {
            error.seasonId = "Please select at least one value";
        }
        if (buyersCollection.name !== "" & buyersCollection.brandId > 0 & buyersCollection.seasonId > 0) {
            try {
                const response = await fetch(`/BuyersCollection/Add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, {
                    method: 'post',
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"]
                    ],
                    body: JSON.stringify(buyersCollection),
                });
                const body = await response.json();
                fetchBuyers();
                if (response.status === 200) {
                    showSweetAlert('saveSuccess', `Buyers Collection`);
                    handleCloseModal();
                }
                else {
                    showSweetAlert('serverError');
                }
            } catch (err) {
                showSweetAlert('serverError');
            }
        }
    }

    const handleEdit = (id) => {
        setBuyerId(id)
        setIsEdit(true)
        let buyersvalue = buyers.filter(x => x.id == id);
        setBuyersCollection(buyersvalue[0])
        handleShowModal();
    }

    const handleUpdateBuyersCollection = async () => {
        showSweetAlert('warning', `Are you sure you want to Update this Buyers Collection?`, () => { updateBuyersCollection() });
    }

    const updateBuyersCollection = async () => {

        if (buyersCollection.name === "") {
            error.name = "Please enter a name";
        }
        if (buyersCollection.brandId <= 0) {
            error.brandId = "Please select at least one value";
        }
        if (buyersCollection.seasonId <= 0) {
            error.seasonId = "Please select at least one value";
        }
        if (buyersCollection.name !== "" & buyersCollection.brandId > 0 & buyersCollection.seasonId > 0) {
            try {
                let url = `/BuyersCollection/Update?id=${buyerId}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
                const response = await fetch((url), {
                    method: 'put',
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"]
                    ],
                    body: JSON.stringify(buyersCollection),
                });
                const body = await response.json();
                fetchBuyers();
                if (response.status === 200) {
                    showSweetAlert('saveSuccess', `Buyers Collection`);
                    handleCloseModal();
                }
                else {
                    showSweetAlert('serverError');
                }
            } catch (err) {
                showSweetAlert('serverError');
            }
        }
    }

    //Handle delete Buyers Collection
    const handleDeleteBuyersCollection = (id) => {
        showSweetAlert('deleteWarning', `Buyers Collection`, () => { deleteBuyersCollection(id) });
    }

    const deleteBuyersCollection = async (id) => {
        try {
            var response = await fetch(`/BuyersCollection/Delete?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'delete' });
            if (response.status === 200) {
                showSweetAlert('deleteSuccess', `Buyers Collection`);
            } else {
                showSweetAlert('warningOkOnly', `Unable to delete buyers collection`);
            }
            await fetchBuyers();
        } catch (err) {
        }
    }

    //Handle delete Buyers Collection Product
    const handleDeleteBuyersCollectionProduct = (id) => {
        showSweetAlert('deleteWarning', 'Buyers collection product', () => { deleteBuyersCollectionProduct(id) });
    }

    const deleteBuyersCollectionProduct = async (id) => {
        try {
            var response = await fetch(`/BuyersCollectionProduct/Delete?id=${id}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, { method: 'delete' });
            if (response.status === 200) {
                showSweetAlert('deleteSuccess', 'Buyers collection product');
                fetchBuyersCollectionProducts(selectedLocation, data.id);
            } else {
                showSweetAlert('warningOkOnly', 'Unable to delete buyers collection product');
            }
        } catch (err) {
        }
    }

    const handleUpdateOpenWithInput = (input) => {
        setOpenWithInput = input;
    }

    const handleSameDetailsCheckboxChange = () => {
        if (buyersCollectionProduct.productVariants.length == 0)
            return;

        if (!isSameDetailsChecked) {
            const updatedProductVariants = buyersCollectionProduct.productVariants.map((variant, index) => {
                const { cost, rrp, vat } = buyersCollectionProduct.productVariants[0];

                return {
                    ...variant,
                    cost,
                    rrp,
                    vat,
                };
            });

            setBuyersCollectionProduct((prevProduct) => ({
                ...prevProduct,
                productVariants: updatedProductVariants,
            }));
        }

        setIsSameDetailsChecked(!isSameDetailsChecked);
    }

    const handleSameDetailsChange = (value, name) => {
        const updatedProductVariants = buyersCollectionProduct.productVariants.map((variant) => ({
            ...variant,
            [name]: value,
        }));

        setBuyersCollectionProduct((prevProduct) => ({
            ...prevProduct,
            productVariants: updatedProductVariants,
        }));
    }

    const handleSameDetailsDropdownChange = (value) => {
        const updatedProductVariants = buyersCollectionProduct.productVariants.map((variant) => ({
            ...variant,
            ["vatRateId"]: value,
        }));

        setBuyersCollectionProduct((prevProduct) => ({
            ...prevProduct,
            productVariants: updatedProductVariants,
        }));
    }

    const handleSizeScaleDropDownChange = (value) => {
        setSelectedSizes([]);
        setSelectedSizeScale(value);
        getAllSizesBySizeScaleId(value);

        setBuyersCollectionProduct((prevProduct) => ({
            ...prevProduct,
            sizeScaleId: value,
        }));
    }

    const getAllSizesBySizeScaleId = async (categoryGroupId) => {
        try {
            let url = `CategoryValue/GetAllByGroup?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=6&categoryGroupId=${categoryGroupId}`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return {
                    ...item,
                    value: item.value,
                }
            });
            setCategorySizes(data);
        } catch (err) {
        }
    }
    const getVAT = async () => {
        try {
            let url = `CategoryValue/GetAllByGroup?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=7`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return {
                    ...item,
                    value: item.value,
                }
            });
            setVat(data);
        } catch (err) {
        }
    }

    const fetchProduct = async (id) => {
        try {
            let url = `/ItemCard/GetSingle?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&productId=${id}`;
            const response = await fetch(url);
            const body = await response.json();
            handleLineChange("costPrice", body.cost);
        } catch (e) {

        }
    }

    const handleCloseProductDetailModal = () => {
        showSweetAlert('warning', `Are you sure you want to cancel? Any changes will not be saved.`, () => { closeProductDetailModal() });
    }
    const closeProductDetailModal = () => {

        handleCloseBuyersCollectionProductDetailModal();
    }

    const handleUpdateProductDetail = () => {
        showSweetAlert('warning', `Are you sure you want to Update these changes?`, () => { updateProductDetail() });
    }

    const updateProductDetail = async () => {
        if (buyersCollectionProduct.name === "") {
            error.name = "Please Enter the Name";
        } else {
            try {
                setBuyersCollectionProductSaving(true);
                let url = `/BuyersCollectionProduct/Update?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&id=${buyersCollectionProductId}`;
                const response = await fetch((url), {
                    method: 'put',
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"]
                    ],
                    body: JSON.stringify(buyersCollectionProduct),
                });
                const body = await response.json();
                if (response.status === 200) {
                    handleCloseBuyersCollectionProductDetailModal();
                    fetchBuyersCollectionProducts(locationId, buyersCollectionProduct.buyersCollectionId);

                    showSweetAlert('saveSuccess', `Buyers Collection Product Detail`);
                } else {
                    showSweetAlert('serverError');
                }
            } catch (err) {
            } finally {
                setBuyersCollectionProductSaving(false);
            }
        }
    }
    const handleAddProductDetail = (openFileBrowser) => {
        setOpenWithInput(openFileBrowser);
        handleCloseBuyersCollectionProductAddModal();
        handleShowBuyersCollectionProductDetailModal();
        setBuyersCollectionProduct({
            id: 0,
            buyersCollectionId: buyersCollectionProductId,
            name: "",
            brandCode: "",
            colour: "",
            productCategoryId: 0,
            productCategory: "",
            sizeScaleId: 0,
            sizeScale: "",
            itemCostsMatch: true,
            images: [
            ],
            productVariants: [
            ]
        })
        //   setSelectedSizeScale(0)
        setSelectedSizes([])
    }
    const handleSaveProductDetail = () => {
        showSweetAlert('warning', `Are you sure you want to save these changes?`, () => { saveProductDetail() });
    }
    const saveProductDetail = async () => {

        if (buyersCollectionProduct.name === "") {
            error.name = "Please Enter the Name";
        } else {
            try {
                setBuyersCollectionProductSaving(true);
                let url = `/BuyersCollectionProduct/Add?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`;
                const response = await fetch((url), {
                    method: 'post',
                    headers: [
                        ["Content-Type", "application/json"],
                        ["Content-Type", "text/plain"]
                    ],
                    body: JSON.stringify(buyersCollectionProduct),
                });

                if (response.status === 200) {
                    handleCloseBuyersCollectionProductDetailModal();
                    fetchBuyersCollectionProducts(locationId, buyersCollectionProduct.buyersCollectionId);
                    showSweetAlert('saveSuccess', `Buyers Collection Product Detail`);
                } else {
                    showSweetAlert('serverError');
                }
            } catch (err) {
            } finally {
                setBuyersCollectionProductSaving(false);
            }
        }
    }

    const fetchCategoryGroups = async () => {
        try {
            let url = `CategoryGroup/GetAll?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=6`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return {
                    ...item,
                    value: item.name,
                }
            });
            setCategoryGroups(data);
        } catch (err) {
        }
    }

    const handleDetailChange = (name, value) => {
        let buyersValues = {
            ...buyersCollectionProduct,
            [name]: value,
        };
        validateInput(name, value)
        setBuyersCollectionProduct(buyersValues)
    }

    const handleProductVariantsChange = (event, index) => {
        var { value, name } = event.target;
        const productVariants = [...buyersCollectionProduct.productVariants];
        productVariants[index][name] = value;
        setBuyersCollectionProduct({ ...buyersCollectionProduct, productVariants });
    };

    const handleProductVariantsCurrencyChange = (value, index, name) => {
        const productVariants = [...buyersCollectionProduct.productVariants];
        productVariants[index][name] = value;
        setBuyersCollectionProduct({ ...buyersCollectionProduct, productVariants });
    };

    const handleProductVariantsDropdownChange = (event, index) => {
        var { value, name } = event;
        const productVariants = [...buyersCollectionProduct.productVariants];
        productVariants[index]["vatRateId"] = parseInt(value);
        setBuyersCollectionProduct({ ...buyersCollectionProduct, productVariants });
    };

    const handleAllocationChange = (id, event, index) => {
        let { value } = event.target;
        value = parseInt(value) || 0;

        // Deep copy the current state to ensure immutability
        const updatedBuyersCollectionProduct = JSON.parse(JSON.stringify(buyersCollectionProduct));

        // Update the allocation quantity
        if (updatedBuyersCollectionProduct.productVariants[id]?.allocations) {
            updatedBuyersCollectionProduct.productVariants[id].allocations[index]["quantity"] = value;
        }

        // Calculate the sum of allocations for the product variant
        const sumAllocations = updatedBuyersCollectionProduct.productVariants[id].allocations
            .reduce((sum, item) => sum + (item.quantity || 0), 0);

        // Update the product variant quantity if necessary
       // if (sumAllocations > (updatedBuyersCollectionProduct.productVariants[id]["quantity"] || 0)) {
            updatedBuyersCollectionProduct.productVariants[id]["quantity"] = sumAllocations;
       // }

        // Update the state
        setBuyersCollectionProduct(updatedBuyersCollectionProduct);
    };

    const handleLocationAllocationChange = (index) => {
        // Deep copy the current state to ensure immutability
        const updatedBuyersCollectionProduct = JSON.parse(JSON.stringify(buyersCollectionProduct));

        // Determine if all allocations at the specified index are currently set to 1
        const allAllocationsOne = updatedBuyersCollectionProduct.productVariants.every(variant =>
            variant?.allocations && variant.allocations[index] && variant.allocations[index]["quantity"] === 1
        );

        // Determine the new quantity based on the current state of allocations
        const newQuantity = allAllocationsOne ? 0 : 1;

        // Loop through all variants and update the specified allocation index for each one
        updatedBuyersCollectionProduct.productVariants = updatedBuyersCollectionProduct.productVariants.map(variant => {
            if (variant?.allocations && variant.allocations[index]) {
                variant.allocations[index]["quantity"] = newQuantity;  // Set the new quantity value

                if (newQuantity == 1) {
                    // Calculate the sum of allocations for the product variant
                    const sumAllocations = variant.allocations
                        .reduce((sum, item) => sum + (item.quantity || 0), 0);

                    // Update the product variant quantity if necessary
                    if (sumAllocations > (variant["quantity"] || 0)) {
                        variant["quantity"] = sumAllocations;
                    }
                }
                else {
                    variant["quantity"]--;
                }
            }
            return variant;
        });

        // Update the state
        setBuyersCollectionProduct(updatedBuyersCollectionProduct);
    };

    const handleUpdateAvailableSizes = (id) => {
        showSweetAlert('warning', `Are you sure you want to overwrite the available sizes for this product?`, () => { updateAvailableSizes(id) });
    }

    const updateAvailableSizes = async (id) => {

        try {
            let url = `/BuyersCollectionProduct/UpdateAvailableSizes?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
            const response = await fetch((url), {
                method: 'put',
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"]
                ],
                body: JSON.stringify(updatedSize),
            });
            if (response.ok) {
                const body = await response.json();
                handleCloseSizeModal();
                const productVariants = [...buyersCollectionProduct.productVariants];

                const updatedProductVariants = [...body];

                await setBuyersCollectionProduct(prevState => ({
                    ...prevState,
                    productVariants: updatedProductVariants
                }));

                // Set same details 
                setIsSameDetailsChecked(true);

                // Set VAT to default 20%
                const rate = vatRates.find(rate => rate.value === "20%");

                const id = rate ? rate.id : null;

                handleSameDetailsDropdownChange(id);
                showSweetAlert('saveSuccess', `Product sizes`);
            } else {
                showSweetAlert('serverError');
            }
        } catch (err) {
        }
    }
    useEffect(() => {
        fetchSatus();
        fetchLocations();
        fetchBrands();
        fetchSeason();
        fetchGender();
        getBuyerCollectionLocationList();
        fetchProductCategoryList();
        fetchCategoryGroups();
        getVAT();

        // set the width of the modal
        document.documentElement.style.setProperty('--modal-width', '1000px');
        document.documentElement.style.setProperty('--modal-width-tablet', '100%');
    }, [])

    useEffect(() => {
    }, [buyersCollectionProduct]);

    useEffect(() => {
        fetchBuyers();
        handlePageChange(0);
    }, [locationId, brandId, seasonId, genderId, statusId, search])
    return (
        <>
            {alert}
            <CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                title={isEdit ? "Edit Buyers Collection" : "Add Buyers Collection"}>
                <AddEditBuyersCollection
                    isEdit={isEdit}
                    season={season}
                    brand={brand}
                    gender={gender}
                    locations={locations}
                    handleDropDownChange={handleChange}
                    handleAddBuyersCollection={handleAddBuyersCollection}
                    handleCloseModal={handleCloseModal}
                    buyersCollection={buyersCollection}
                    handleUpdateBuyersCollection={handleUpdateBuyersCollection}
                    validateInput={validateInput}
                    error={error}
                />
            </CustomModal>
            {
                showBuyersCollectionProductAddModal === false && showBuyersCollectionProductDetailModal === false &&
                <CustomModal
                    show={showCollectionDetailModal}
                    handleClose={handleCloseCollectionDetailModal}
                    backdrop="static"
                >
                    <BuyersCollectionDetailModal
                        data={data}
                        showDetails={showDetails}
                        setShowDetails={setShowDetails}
                        buyerCollectionLocations={buyerCollectionLocations}
                        collectionLocationDetails={collectionLocationDetails}
                        buyersCollectionData={buyersCollectionData}
                        buyersCollectionProductAddModal={buyersCollectionProductAddModal}
                        addBuyersCollectionAllocation={addBuyersCollectionAllocation}
                        handleEditBuyersCollectionProductDetailModal={handleEditBuyersCollectionProductDetailModal}
                        fetchBuyersCollectionProducts={fetchBuyersCollectionProducts}
                        selectedLocation={selectedLocation}
                        allocationMessage={allocationMessage}
                        handleGetItemCard={handleGetItemCard}
                        isLoading={isLoading}
                        handleApprovalChange={handleApprovalChange}
                        handleDeleteBuyersCollectionProduct={handleDeleteBuyersCollectionProduct}
                        handleDownloadImages={handleDownloadImages}
                        handleExportPDF={handleExportPDF}
                    />
                </CustomModal>
            }
            <CustomModal
                show={showBuyersCollectionProductAddModal}
                handleClose={handleCloseBuyersCollectionProductAddModal}
                backdrop="static"
            >
                <BuyersCollectionProductAddModal
                    handleCloseBuyersCollectionProductAddModal={handleCloseBuyersCollectionProductAddModal}
                    handleChange={handleChange}
                    handleChangeImage={handleChangeImage}
                    handleClickImage={handleClickImage}
                    handleAddProductDetail={handleAddProductDetail}
                />
            </CustomModal>
            <CustomModal
                show={showCommentsModal}
                handleClose={setShowCommentsModal}
                backdrop="static"
            >
                <ApprovalCommentsInput
                    handleCommentConfirm={handleCommentConfirm}
                    handleCancelApprovalComments={handleCancelApprovalComments}
                    setShowCommentsModal={setShowCommentsModal}
                />
            </CustomModal>
            <CustomModal
                show={showItemCardModal}
                handleClose={handleItemCardCloseModal}
                title=""
                backdrop="static">
                <ItemCard
                    id={selectedProductId}
                    handleShowInventoryModal={handleShowInventoryModal}
                    selectedInventory={selectedInventory}
                    itemCardSelectedTab={itemCardSelectedTab}
                    setItemCardSelectedTab={setItemCardSelectedTab}
                    handleGetLabelPDF={handleGetLabelPDF}
                />
            </CustomModal>
            <CustomModal
                show={showInventoryModal}
                handleClose={handleHideInventoryModal}
                title=""
                backdrop="static">
                <InventoryModal
                    selectedInventory={selectedInventory}
                    handleHideInventoryModal={handleHideInventoryModal}
                />
            </CustomModal>


            {
                showSizeModal === false &&
                <CustomModal
                    show={showBuyersCollectionProductDetailModal}
                    handleClose={handleCloseBuyersCollectionProductDetailModal}
                    backdrop="static"
                >
                    <BuyersCollectionProductDetailModal
                        productCategory={productCategory}
                        handleBuyersCollectionSizeModal={handleBuyersCollectionSizeModal}
                        handleCloseBuyersCollectionProductDetailModal={handleCloseBuyersCollectionProductDetailModal}
                        vat={vat}
                        buyersCollectionProduct={buyersCollectionProduct}
                        handleDetailChange={handleDetailChange}
                        handleCloseProductDetailModal={handleCloseProductDetailModal}
                        handleSaveProductDetail={handleSaveProductDetail}
                        handleAllocationChange={handleAllocationChange}
                        handleLocationAllocationChange={handleLocationAllocationChange}
                        handleProductVariantsChange={handleProductVariantsChange}
                        handleProductVariantsCurrencyChange={handleProductVariantsCurrencyChange}
                        handleUpdateProductDetail={handleUpdateProductDetail}
                        handleClickImage={handleClickImage}
                        handleChangeImage={handleChangeImage}
                        handleDeleteImage={handleDeleteImage}
                        error={error}
                        validateInput={validateInput}
                        handleProductVariantsDropdownChange={handleProductVariantsDropdownChange}
                        isSameDetailsChecked={isSameDetailsChecked}
                        handleSameDetailsCheckboxChange={handleSameDetailsCheckboxChange}
                        handleSameDetailsChange={handleSameDetailsChange}
                        handleSameDetailsDropdownChange={handleSameDetailsDropdownChange}
                        handleUpdateOpenWithInput={handleUpdateOpenWithInput}
                        openWithInput={openWithInput}
                        handleAddProductDetail={handleAddProductDetail}
                        buyersCollectionProductSaving={buyersCollectionProductSaving}
                    />
                </CustomModal>
            }
            <CustomModal
                show={showSizeModal}
                handleClose={handleCloseSizeModal}
                backdrop="static"
            >
                <BuyersCollectionSizeModal
                    productId={buyersCollectionProduct.id}
                    categoryGroups={categoryGroups}
                    categorySizes={categorySizes}
                    handleCloseSizeModal={handleCloseSizeModal}
                    handleSizeScaleDropDownChange={handleSizeScaleDropDownChange}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectAllChange={handleSelectAllChange}
                    sizeSelectAll={sizeSelectAll}
                    handleUpdateAvailableSizes={handleUpdateAvailableSizes}
                    selectedSizeScale={selectedSizeScale}
                    selectedSizes={selectedSizes}
                />
            </CustomModal>
            {getPermission(user.permissions, BUYERS_VIEW) &&
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col md="">
                                            <CustomSelect
                                                title="Brand"
                                                placeholder="Brand"
                                                id={brandId}
                                                data={brand}
                                                handleChange={(e) => setBrandId(e.value)}
                                                withAll={true}
                                            />
                                        </Col>
                                        <Col md="">
                                            <CustomSelect
                                                title="Season"
                                                placeholder="Season"
                                                id={seasonId}
                                                data={season}
                                                handleChange={(e) => setSeasonId(e.value)}
                                                withAll={true}
                                            />
                                        </Col>
                                        <Col md="">
                                            <CustomSelect
                                                title="Status"
                                                placeholder="Status"
                                                id={statusId}
                                                data={status}
                                                handleChange={(e) => setstatusId(e.value)}
                                                withAll={true}
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <BuyersTable
                                data={buyers}
                                isLoading={isLoading}
                                handleSearchChange={handleSearchChange}
                                handleShowModal={handleShowAddBuyersCollection}
                                handleEdit={handleEdit}
                                handleDeleteBuyersCollection={handleDeleteBuyersCollection}
                                handleCollectionProductDetailModal={handleCollectionProductDetailModal}
                                handleExportTemplate={handleExportTemplate}
                            />
                        </Col>
                    </Row>
                </Container>
            }
            {!getPermission(user.permissions, BUYERS_VIEW) &&
                <Container className="mt-4 ms-4">You are not authorized to view this page</Container>
            }


        </>
    )
}
export default Buyers