import React from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import CustomSelect from "components/Shared/CustomSelect";
function AddEditMovementsModal(props) {
  return (
    <>
      <Form action="" className="form" method="">
        <Card>
          <Row>
            <Col className="pr-1" md="6">
              <Form.Group>
                <label> Location from</label>
                <CustomSelect
                  placeholder=" Location from"
                  data={props.getLocationDropdown}
                  value={props.movementChangedValue?.fromLocationId}
                  handleChange={(e) =>
                    props.handleChangeLocation("fromLocationId", e.value)
                  }
                  withAll={false}
                />
                {props.error.fromLocationId && (
                  <label style={{ color: "red" }} className="err">
                    {props.error.fromLocationId}
                  </label>
                )}
              </Form.Group>
            </Col>
            <Col className="pr-1" md="6">
              <Form.Group>
                <label> Location to</label>
                <CustomSelect
                  placeholder=" Location to"
                  data={props.getLocationDropdown}
                  value={props.movementChangedValue?.toLocationId}
                  handleChange={(e) =>
                    props.handleChangeLocation("toLocationId", e.value)
                  }
                  withAll={false}
                />
                {props.error.toLocationId && (
                  <label style={{ color: "red" }} className="err">
                    {props.error.toLocationId}
                  </label>
                )}
              </Form.Group>
            </Col>
            <Col className="pr-1" md="6">
              <Form.Group>
                <label> Type Name</label>
                <Form.Control
                  placeholder="Type Name"
                  type="text"
                  value="IntercompanyTransfer"
                  disabled
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Card>
        <Button
          className="btn-fill float-end"
          variant="primary"
          onClick={props.handleSaveMovement}
        >
          Save
        </Button>
      </Form>
    </>
  );
}
export default AddEditMovementsModal;
