import React, { useState, useEffect, useMemo, useRef } from "react";
import moment from 'moment';
import {
    Card,
    Container,
    Row,
    Col,
    Button,
    Form,
} from "react-bootstrap";
import { useSelector } from 'react-redux'

import CustomSelect from '../../components/Shared/CustomSelect';
import { showSweetAlert } from 'components/Shared/CustomAlert';
import { Label } from "reactstrap";

import CustomModal from "components/Shared/CustomModal";
import Loading from "components/Shared/Loading";
import animationData from '../../assets/animations/orange-square.json';

import { BULKUPDATE_VIEW } from 'constants/Permissions.js';
import { getPermission } from 'utils/Permissions.js';

function BulkUpdate() {
    const user = useSelector((state) => state.user);

    // ### STATE ###
    const [isLoading, setIsLoading] = useState(true);

    const [seasons, setSeasons] = useState([]);
    const [brands, setBrands] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [locations, setLocations] = useState([]);

    const [seasonId, setSeasonId] = useState(0);
    const [brandId, setBrandId] = useState(0);
    const [statusId, setStatusId] = useState(0);
    const [categoryId, setCategoryId] = useState(0);

    const [selectAll, setSelectAll] = useState(false);
    const [isArchived, setIsArchived] = useState(false);

    const [isImportLoading, setIsImportLoading] = useState(false);
    const [isExportLoading, setIsExportLoading] = useState(false);

    const fileInputRef = useRef(null);
    let files = "";

    const [exportFields, setExportFields] = useState({
        name: false,
        cost: false,
        price: false,
        salePrice: false,
        brandCode: false,
        supplierBarcode: false,
        sku: false,
        supplier: false,
        status: false,
        gender: false,
        brand: false,
        productCategory: false,
        season: false,
        colour: false,
        size: false,
        tags: false,
        locations: false,
    });

    const [selectedLocations, setSelectedLocations] = useState([]);

    // ### HANDLERS ###
    const handleCheckboxChange = (key) => {
        setExportFields((prevFilters) => ({
            ...prevFilters,
            [key]: !prevFilters[key],
        }));
    };

    const handleImport = () => {
        fileInputRef.current.click();
    }

    const handleFileUpload = (event) => {
        setIsImportLoading(true);
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryString = event.target.result;
            const base64String = btoa(binaryString);
            files = base64String;
            marginCheck();
        };
        reader.readAsBinaryString(file);
    };

    const marginCheck = async () => {
        try {
            setIsLoading(true);
            const payload = files
            const response = await fetch(`Product/ImportProductExcelValidateMargin?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
            })
            const body = await response.json();

            if (body > 0) {
                setIsImportLoading(false);
                showSweetAlert('warningCustomMessage', `You have imported ${body} products that fall below the minimum margin. Are you sure you wish to continue?`, () => { importFile() });
            } else if (body == 0) {
                importFile();

            } else {
                setIsImportLoading(false);
                showSweetAlert('serverError');
            }
        }
        catch (e) {
            setIsImportLoading(false);
            showSweetAlert('serverError');
        }
        finally {
            setIsLoading(false);
          //  fetchPurchaseOrderData();
        }
    }

    const importFile = async () => {
        try {
            setIsImportLoading(true);
            const payload = files
            const response = await fetch(`Product/ImportProductExcel?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
            })
            const body = await response.json();
            
            if (body.errorMessages.length > 0) {
                const message = body.errorMessages.map((error) => {
                    return "<br />" + error
                })
                showSweetAlert('warningOkOnly', `Bulk import imported ${body.successfulImportCount} out of ${body.successfulImportCount + body.errorMessages.length} lines. <br /><br /><b>Errors:</b><br /> ${message}`);
            } else {
                showSweetAlert('saveSuccess', `Bulk import successfully imported all ${body.successfulImportCount} lines`);
            }
        }
        catch (e) {
            showSweetAlert('serverError');
        }
        finally {
            setIsImportLoading(false);
        }
    }

    const handleExport = () => {
        showSweetAlert('warning', `Are you sure you want to export the selected items?`, () => { exportBulkUpdate() });
    }

    const exportBulkUpdate = async () => {
        setIsLoading(true);
        setIsExportLoading(true);

        try {
            // compile export model
            const productExcelExportModel = {
                filters: {
                    seasonId: seasonId,
                    brandId: brandId,
                    statusId: statusId,
                    productCategoryId: categoryId,
                },
                fields: {
                    ...exportFields, locations: selectedLocations
                }
            }

            const url = `Product/GetProductExcelExport?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}`
            const response = await fetch(url, {
                method: 'POST',
                headers: [
                    ["Content-Type", "application/json"],
                ],
                body: JSON.stringify(productExcelExportModel),
            });

            const base64String = await response.text();
            const binaryData = Buffer.from(base64String, 'base64');
            const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Save file
            const Newurl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = Newurl;
            link.setAttribute('download', 'BulkExport.xlsx');
            link.click();
            setIsLoading(false)
        } catch (e) {
            showSweetAlert('serverError');
        }
        finally {
            setIsExportLoading(false);
        }
    }

    const handleLocationChange = (locationId) => {
        // Check if the locationId is already in the selectedLocations array
        const isSelected = selectedLocations.includes(locationId);

        // If the locationId is selected, remove it from the array
        if (isSelected) {
            const updatedLocations = selectedLocations.filter((id) => id !== locationId);
            setSelectedLocations(updatedLocations);
        } else {
            // If the locationId is not selected, add it to the array
            setSelectedLocations([...selectedLocations, locationId]);
        }
    };

    const handleSelectAll = () => {
        // If selectAll is true, deselect all exportFields
        if (selectAll) {
            const updatedFields = {};
            Object.keys(exportFields).forEach((key) => {
                updatedFields[key] = false;
            });
            setExportFields(updatedFields);
        } else {
            // If selectAll is false, select all exportFields
            const updatedFields = {};
            Object.keys(exportFields).forEach((key) => {
                updatedFields[key] = true;
            });
            setExportFields(updatedFields);
        }

        // Toggle the selectAll state
        setSelectAll((prevSelectAll) => !prevSelectAll);
    };

    //// ### DATA FETCHES ##
    //const fetchPurchaseOrderData = async () => {
    //    try {
    //        let from = new Date(dateRange[0]);

    //        let url = `/PurchaseOrder/getallpaged?skip=${skip}&take=${take}&brandId=${brandId}&seasonId=${seasonId}&statusId=${statusId}&from=${moment(from, 'YYYY-MM-DD').format('YYYY-MM-DD')}&to=${dateRange[1] === null ? moment(from, 'YYYY-MM-DD').format('YYYY-MM-DD') : moment(dateRange[1], 'YYYY-MM-DD').format('YYYY-MM-DD')}&userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&orderBy=${orderBy}&descending=${descending}`;

    //        const response = await fetch(url);
    //        const body = await response.json();
    //        setIsLoading(false);
    //        setOriginData(body);
    //        setData(body);
    //    } catch (err) {
    //        setIsLoading(false);
    //    }
    //}

    const fetchSeasonDropdownList = async () => {
        try {
            let seasonUrl = `/CategoryValue/GetDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=4&includeArchived=${isArchived}`;
            const seasonResponse = await fetch(seasonUrl);
            const seasonBody = await seasonResponse.json();
            const seasonData = seasonBody?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setSeasons(seasonData);
        } catch (err) {
        }
    }

    const fetchBrandDropdownList = async () => {
        try {
            let brandUrl = `/CategoryValue/GetDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=2&includeArchived=${isArchived}`;
            const brandResponse = await fetch(brandUrl);
            const brandBody = await brandResponse.json();
            const brandData = brandBody?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setBrands(brandData);
        } catch (err) {
        }
    }

    const fetchStatusDropdownList = async () => {
        try {
            let Url = `/Product/GetStatusDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&includeArchived=${isArchived}`;
            const Response = await fetch(Url);
            const Body = await Response.json();
            const data = Body?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setStatuses(data);
        } catch (err) {
        }
    }

    const fetchCategoriesDropdownList = async () => {
        try {
            let categoryUrl = `/CategoryValue/GetDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&categoryId=3&includeArchived=${isArchived}`;
            const categoryResponse = await fetch(categoryUrl);
            const categoryBody = await categoryResponse.json();
            const categoryData = categoryBody?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setCategories(categoryData);
        } catch (err) {
        }
    }

    const fetchLocationDropdownList = async () => {
        try {
            let locationUrl = `/location/GetDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&includeArchived=${isArchived}`;
            const locationResponse = await fetch(locationUrl);
            const locationBody = await locationResponse.json();
            const locationData = locationBody?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setLocations(locationData);
        } catch (err) {
        }
    }

    // ### HOOKS ###
    useEffect(() => {
        fetchSeasonDropdownList();
        fetchStatusDropdownList();
        fetchBrandDropdownList();
        fetchCategoriesDropdownList();
        fetchLocationDropdownList();
    }, []);

    return (
        <>
            {getPermission(user.permissions, BULKUPDATE_VIEW) &&
                <Container fluid>
                    <Container fluid>
                        <CustomModal
                            show={isImportLoading}>
                            <Loading animationData={animationData} title="Import in progress..." />
                        </CustomModal>
                        <CustomModal
                            show={isExportLoading}>
                            <Loading animationData={animationData} title="Export in progress..." />
                        </CustomModal>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md="">
                                        <CustomSelect
                                            title="Brand"
                                            placeholder="Brand"
                                            id={brandId}
                                            data={brands}
                                            handleChange={(e) => setBrandId(e.value)}
                                            withAll={true}
                                        />
                                    </Col>
                                    <Col md="">
                                        <CustomSelect
                                            title="Season"
                                            placeholder="Season"
                                            id={seasonId}
                                            data={seasons}
                                            handleChange={(e) => setSeasonId(e.value)}
                                            withAll={true}
                                        />
                                    </Col>
                                    <Col md="">
                                        <CustomSelect
                                            title="Status"
                                            placeholder="Status"
                                            id={statusId}
                                            data={statuses}
                                            handleChange={(e) => setStatusId(e.value)}
                                            withAll={true}
                                        />
                                    </Col>
                                    <Col md="">
                                        <CustomSelect
                                            title="Category"
                                            placeholder="Category"
                                            id={categoryId}
                                            data={categories}
                                            handleChange={(e) => setCategoryId(e.value)}
                                            withAll={true}
                                        />
                                    </Col>
                                    <Col md="" className="archivedCol">
                                        <Form.Check
                                            onChange={isArchived}
                                            type="switch"
                                            id="custom-switch"
                                        />
                                        <h5 className="archived" style={{ width: "119px" }}>Include archived</h5>
                                    </Col>
                                    <Col md="" >
                                        <Button onClick={handleImport} style={{ width: "163px" }}
                                            className="btn-fill PO"
                                            variant="primary"
                                        >
                                            Import Bulk Update
                                        </Button>
                                    </Col>
                                    <Form.Control
                                        placeholder="Import Bulk Update"
                                        type="file"
                                        accept=".xlsm, .xlsx"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileUpload}
                                    ></Form.Control>
                                </Row>
                                <Row className="m-2 pt-4">
                                    <Col md="6">Select the columns you want to export:</Col>
                                    <Col md="6">
                                        <Form.Check
                                            type="switch"
                                            label="Select All"
                                            checked={selectAll}
                                            onChange={() => handleSelectAll()}
                                        />
                                    </Col>
                                    {Object.keys(exportFields).map((key) => (
                                        <>
                                            <Col md="3">
                                                <Form.Check
                                                    key={key}
                                                    label={key}
                                                    type="switch"
                                                    checked={exportFields[key]}
                                                    onChange={() => handleCheckboxChange(key)}
                                                />

                                            </Col>
                                            {key == "locations" && exportFields.locations && (
                                                <Row className="block-example border border-dark m-2 p-2">
                                                    {locations.map((item) => (
                                                        <Col md="3"
                                                            key={`location-${item.id}`}>
                                                            <Form.Check
                                                                label={item.value}
                                                                type="switch"
                                                                checked={selectedLocations.includes(item.id)}
                                                                onChange={() => handleLocationChange(item.id)}
                                                            />
                                                        </Col>
                                                    ))}
                                                </Row>

                                            )}
                                        </>
                                    ))}
                                    <Col md="12" >
                                        <Button onClick={handleExport}
                                            variant="primary"
                                        >
                                            Export Bulk Update
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>
                </Container>
            }
            {!getPermission(user.permissions, BULKUPDATE_VIEW) &&
                <Container className="mt-4 ms-4">You are not authorized to view this page</Container>
            }
        </>
    );
}

export default BulkUpdate;
