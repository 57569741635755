import React, { useState, useEffect } from "react";
// react-bootstrap components
import {
  Form,
  Card,
  Col,
  Button,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Dropdown,
} from "react-bootstrap";
import { showSweetAlert } from "components/Shared/CustomAlert";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../utils/FormatDate";

function WebOrdersModal(props) {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "1200px");
  }, []);

  const history = useHistory();

  const navigateToManageMovements = (order) => {
    history.push({
      pathname: "/manage-movements", // the path of the new page
      state: { order: order }, // the state you want to pass
    });
  };

  const navigateToOrders = (order) => {
    history.push({
      pathname: "/orders", // the path of the new page
      state: { order: order }, // the state you want to pass
    });
  };

  //Print batch in pdf
  const handlePrintBatchLabel = async (batchId, batchName) => {
    try {
      const url = `Movement/PrintBatchLabel?userId=${localStorage.getItem(
          "userId"
      )}&userToken=${localStorage.getItem("userToken")}&batchId=${batchId}`;
      const response = await fetch(url);

      if (response.status == 200) {
        const base64String = await response.text();

        try {
          const decodedData = Buffer.from(base64String, "base64").toString(
              "binary"
          );
          const dataArray = new Uint8Array(
              Array.from(decodedData, (char) => char.charCodeAt(0))
          );
          const blob = new Blob([dataArray], { type: "application/pdf" });

          const blobUrl = window.URL.createObjectURL(blob);
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.src = blobUrl;
          document.body.appendChild(iframe);

          iframe.onload = function () {
            try {
              iframe.contentWindow.print();
            } catch (printError) {
              console.error("Error in printing:", printError);
            }
          };
        } catch (error) {
          console.error("Error in processing the PDF:", error);
        }
      } else {
        const body = await response.text();
        showSweetAlert("warningCustomMessage", body);
      }
    } catch (e) {
      showSweetAlert("serverError");
    }
  };

  //Print batch labels to pdf
  const handleGetLabelPDF = async (id) => {
    try {
      const url = `Product/GetProductLabelsPDF?userId=${localStorage.getItem(
          "userId"
      )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
      const response = await fetch(url);

      if (response.status == 200) {
        const base64String = await response.text();

        try {
          const decodedData = Buffer.from(base64String, "base64").toString(
              "binary"
          );
          const dataArray = new Uint8Array(
              Array.from(decodedData, (char) => char.charCodeAt(0))
          );
          const blob = new Blob([dataArray], { type: "application/pdf" });
          const blobUrl = window.URL.createObjectURL(blob);
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.onload = function () {
            try {
              iframe.contentWindow.print();
            } catch (printError) {
              console.error("Error in printing:", printError);
            }
          };
        } catch (error) {
          console.error("Error in processing the PDF:", error);
        }
      } else {
        const body = await response.text();
        showSweetAlert("warningCustomMessage", body);
      }
    } catch (e) {
      showSweetAlert("serverError");
    }
  };


  // Movements table
  const returnMovementsTable = () => {
    const returnStatusName = (statusId) => {
      switch (statusId) {
        case 1:
          return "Open";
        case 2:
          return "Open";
        case 3:
          return "In Transit";
        default:
          return "N/A";
      }
    };

    return (
      <Table responsive>
        <thead>
        <tr>
          <th>Id</th>
          <th>BatchName</th>
          <th>From Location</th>
          <th>To Location</th>
          <th>Type Name</th>
          <th>Units</th>
          <th>Status</th>
          <th>Date Created</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
          {props.itemCardWebOrderInfo.map((order, index) => {
            console.log("order", order);
            return (
                <tr
                    key={index}
                >
                  <td onClick={() => navigateToManageMovements(order)}
                      style={{cursor: "pointer"}}>
                    <a href="#id">{order.id}</a>
                  </td>
                  <td>{order.batchName ?? "N/A"}</td>
                  <td>{order.fromLocationName ?? "N/A"}</td>
                  <td>{order.toLocationName ?? "N/A"}</td>
                  <td>{order.typeName ?? "N/A"}</td>
                  <td>{order.units} ({order.unitsBookedIn})</td>
                  <td>{returnStatusName(order.statusId)}</td>
                  <td>{formatDate(order.dateCreated)}</td>
                  <td>
                    <OverlayTrigger
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        overlay={
                          <Tooltip id="tooltip-255158527">Download Batch Label</Tooltip>
                        }
                    >
                      <Button
                          className="fa-sharp fa-light fa-arrow-down-to-line"
                          href="#pablo"
                          onClick={(e) => handlePrintBatchLabel(order.id, order.batchName)}
                          variant="link"
                      >
                        <i className="fa fa-qrcode"></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        overlay={
                          <Tooltip id="tooltip-255158527">Download Labels PDF</Tooltip>
                        }
                    >
                      <Button
                          href="#"
                          onClick={(e) =>
                              handleGetLabelPDF(props.selectedProductId, null)
                          }
                          variant="link"
                      >
                        <i className="fa fa-tag"></i>
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  // // orders table
  const returnOrdersTable = () => {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Order</th>
            <th>Total Price</th>
            <th>Sub Total</th>
            <th>Total Tax</th>
            {props.itemCardWebOrderInfoType !== "Sold" && <th>Quantity</th>}
            <th>Fulfillment Status</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {props.itemCardWebOrderInfo.map((order, index) => {
            return (
              <tr
                key={index}
                onClick={() => navigateToOrders(order)}
                style={{ cursor: "pointer" }}
              >
                <td>
                  <a href="#id">{order.orderNumber ?? "N/A"}</a>
                </td>
                <td>{`£${order?.totalPrice?.toFixed(2)}` ?? "N/A"}</td>
                <td>{`£${order?.subtotal?.toFixed(2)}` ?? "N/A"}</td>
                <td>{`£${order?.totalTax?.toFixed(2)}` ?? "N/A"}</td>
                {props.itemCardWebOrderInfoType !== "Sold" && (
                  <td>{order.quantity ?? "N/A"}</td>
                )}
                <td>{order.fulfillmentStatus ?? "N/A"}</td>
                <td>
                  {order.locationId === 0 ? "Web" : order.location ?? "N/A"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h4">{props.itemCardWebOrderInfoType}</Card.Title>
        {/* <Col md="3" style={SearchStyle}>
            <CustomSearchBox
              placeholder="Search"
              value={props.search}
              handleChange={(e) => props.handleSearchChange(e)}
              handleClear={() => props.handleSearchChange("")}
            />
          </Col> */}
      </Card.Header>
      <Card.Body>
        {props.itemCardWebOrderInfoType === "In Transit"
          ? returnMovementsTable()
          : returnOrdersTable()}
      </Card.Body>
    </Card>
  );
}
export default WebOrdersModal;
