import React from 'react';
import {
    Col,
    Row,
} from "react-bootstrap";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = {
    IMAGE: 'image'
};
function DraggableImage({ img, index, moveImage, setSelectedImage, handleDeleteImage, showDelete }) {
    const [, ref] = useDrag({
        type: ItemType.IMAGE,
        item: { index }
    });

    const [, drop] = useDrop({
        accept: ItemType.IMAGE,
        drop: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveImage(draggedItem.index, index);
            }
        }
    });

    return (
        <div
            ref={(node) => ref(drop(node))}
            style={{
                position: 'relative', // Add relative positioning to the div
                width: '100px',
                height: '100px',
            }}
        >
            <img
                src={img.source}
                alt={`Thumbnail ${index + 1}`}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    position: 'relative', // Add relative positioning to the image
                }}
                onClick={() => setSelectedImage(img.source)}
            />
            {showDelete &&
                <i
                    className="fas fa-times"
                    onClick={() => handleDeleteImage(index)}
                    style={{
                        position: 'absolute', // Add absolute positioning to the X icon
                        top: '5px',
                        right: '5px',
                        fontSize: '1.2rem',
                        cursor: 'pointer',
                        color: 'red',
                        zIndex: 1, // Ensure the X icon appears above the image
                    }}
                />
            }
        </div>
    );
}

function ImageComponent({ images, updateProductImageOrder, setSelectedImage, handleDeleteImage, showDelete }) {
    const moveImage = (fromIndex, toIndex) => {
        updateProductImageOrder(fromIndex, toIndex);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Row className="mt-1">
                {images && images.slice(0, 6).map((img, index) => (
                    <Col md="4" key={index}>
                        <DraggableImage img={img} index={index} moveImage={moveImage} setSelectedImage={setSelectedImage} handleDeleteImage={handleDeleteImage} showDelete={showDelete}  />
                    </Col>
                ))}
            </Row>
        </DndProvider>
    );
}

export default ImageComponent;
